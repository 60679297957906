// @ts-nocheck
import axios from 'util/axios'
import { LocationResponse, Location, Attributes, LocationQueryParams } from './index'
import { organizationId } from '../path'

const toResourceModel = (location: Location) => ({
  attributes: {
    name: location.name,
    address: location.address,
    city: location.city,
    state: location.state,
    postal_code: location.postal_code,
    external_id: location.external_id,
    time_zone: location.time_zone,
    phone_number: location.phone_number,
  },
})

export async function get(params: LocationQueryParams) {
  return axios
    .get<{ data: LocationResponse[] }>(`/organizations/${organizationId()}/locations`, {
      params: {
        size: 10001,
        [`filter[postal_code]`]: params?.zipcode,
      },
    })
    .then((response) => response?.data?.data)
}

export async function update(location: Location) {
  return axios
    .patch<{ data: LocationResponse }>(
      `/organizations/${organizationId()}/locations/${location.id}`,
      { data: toResourceModel(location) }
    )
    .then((response) => response?.data?.data)
}

export async function create(attributes: Attributes) {
  return axios
    .post<{ data: LocationResponse }>(`/organizations/${organizationId()}/locations`, {
      data: { attributes },
    })
    .then((response) => response?.data?.data)
}

export async function destroy(id: string) {
  return axios
    .delete<{ data: LocationResponse }>(`/organizations/${organizationId()}/locations/${id}`)
    .then((response) => response?.data?.data)
}
