import dayjs from 'dayjs'
import formatter from 'ical.js'

import { RRule } from 'rrule'
import { v4 as uuid } from 'uuid'

const KROW_RESERVATION_TYPE_DECORATOR = 'x-krow-reservation-type'
const KROW_RESERVATION_ID_DECORATOR = 'x-krow-reservation-id'
const KROW_RESERVATION_DURATION_DECORATOR = 'x-krow-reservation-duration'
const KROW_RESERVATION_STATE_DECORATOR = 'x-krow-reservation-state'
const KROW_INTERVIEW_APPLICANT_PHONE = 'x-krow-interview-applicant-phone'
const KROW_INTERVIEW_CHECKED_IN_DECORATOR = 'x-krow-interview-checked-in'

const KROW_INTERVIEW_TYPE = 'interview'
const KROW_SCHEDULE_TYPE = 'schedule'
const KROW_PREOCCUPATION_TYPE = 'preoccupation'
const KROW_ELAPSED_TYPE = 'elapsed'

const summarize = ({ summary, directed, starting, ending, until }) => {
  const day = dayjs.weekdays()[starting.day()]

  if (0 < summary.length) {
    return summary
  } else if (directed) {
    return [
      starting.format('dddd, MMM D, YYYY'),
      'from',
      starting.format('h:mm a'),
      'to',
      !until || starting.isSame(until, 'day')
        ? ending.format('h:mm a')
        : until.format('dddd, MMM D, YYYY h:mm a'),
    ].join(' ')
  } else {
    const rule = new RRule({
      freq: RRule.WEEKLY,
      interval: 1,
      byweekday: [RRule[day.substring(0, 2).toUpperCase()]],
      dtstart: starting.toDate(),
    })

    return [
      [rule.toText().charAt(0).toUpperCase(), rule.toText().slice(1)].join(''),
      'from',
      starting.format('h:mm a'),
      'to',
      ending.format('h:mm a'),
    ].join(' ')
  }
}

const equality = (first, second) =>
  first.properties[KROW_RESERVATION_TYPE_DECORATOR] ===
    second.properties[KROW_RESERVATION_TYPE_DECORATOR] &&
  first.properties[KROW_RESERVATION_ID_DECORATOR] ===
    second.properties[KROW_RESERVATION_ID_DECORATOR] &&
  first.starting.isSame(second.starting) &&
  first.ending.isSame(second.ending)

const calendar = (() => {
  const parse = ({ ical }) => {
    // eslint-disable-next-line
    const [, details, components] = formatter.parse(ical)
    // eslint-disable-next-line
    const [, tzid, _] = components.find(([type]) => type.toLowerCase() === 'vtimezone')

    const zone = tzid[0][3]

    const reservations = components
      .filter(([type]) => type.toLowerCase() === 'vevent')
      .map(([type, information, _]) => {
        const id = information.find(([name]) => name.toLowerCase() === 'uid')[3]
        const stamp = dayjs.utc(information.find(([name]) => name.toLowerCase() === 'dtstamp')[3])
        const starting = dayjs.utc(
          information.find(([name]) => name.toLowerCase() === 'dtstart')[3]
        )
        const ending = dayjs.utc(information.find(([name]) => name.toLowerCase() === 'dtend')[3])

        const summary =
          (information.find(([name]) => name.toLowerCase() === 'summary') || [])[3] || ''
        const description =
          (information.find(([name]) => name.toLowerCase() === 'description') || [])[3] || ''

        const properties = information
          .filter(([name, options, type, value]) => type === 'unknown')
          .reduce((store, [name, options, type, value]) => ({ ...store, [name]: value }), {})

        const recurrence = (information.find(([name]) => name.toLowerCase() === 'rrule') || [])[3]

        const rrule = recurrence
          ? new RRule({
              freq: RRule[recurrence.freq],
              byweekday: recurrence.byday,
              dtstart: starting.toDate(),
            })
          : null

        return {
          id,
          stamp,
          starting,
          ending,
          summary,
          description,
          properties,
          rrule,
          persisted: true,
        }
      })

    return { zone, reservations }
  }

  return { parse }
})()

const schedule = (() => {
  const SCHEDULE_KIND = 'itinerary_setting_structure/schedule'
  const HOLIDAY_KIND = 'itinerary_setting_structure/holiday'

  const defaults = [
    ['compression', 15],
    ['occupancy', 1],
    ['duration', 30],
    ['active', true],
    ['directed', false],
  ].reduce((store, [property, value]) => ({ ...store, [property]: value }), {})

  const parse = ({ ical, ...attributes }) => {
    // eslint-disable-next-line
    const [, parsed, _] = formatter.parse(ical)

    const id = parsed.find(([name]) => name.toLowerCase() === 'uid')[3]
    const stamp = dayjs.utc(parsed.find(([name]) => name.toLowerCase() === 'dtstamp')[3])
    const starting = dayjs.utc(parsed.find(([name]) => name.toLowerCase() === 'dtstart')[3])
    const ending = dayjs.utc(parsed.find(([name]) => name.toLowerCase() === 'dtend')[3])

    const rrule = (parsed.find(([name]) => name.toLowerCase() === 'rrule') || [])[3] || ''
    const until = rrule && rrule['until'] ? dayjs.utc(rrule['until']) : undefined

    const summary = (parsed.find(([name]) => name.toLowerCase() === 'summary') || [])[3] || ''

    const description =
      (parsed.find(([name]) => name.toLowerCase() === 'description') || [])[3] || ''

    const properties = parsed
      .filter(([name, options, type, value]) => type === 'unknown')
      .reduce((store, [name, options, type, value]) => ({ ...store, [name]: value }), {})

    return {
      id,
      stamp,
      starting,
      ending,
      until,
      summary,
      description,
      properties,
      persisted: true,
      dirty: false,
      ...attributes,
    }
  }

  const compile = ({
    id,
    stamp,
    starting,
    ending,
    summary,
    description,
    persisted,
    properties = {},
    directed = false,
    holiday = false,
    until = null,
    ...attributes
  }) => {
    const day = dayjs.weekdays()[starting.day()]

    let details = [
      ['uid', {}, 'text', id],
      ['dtstamp', {}, 'date-time', stamp.format()],
      ['dtstart', {}, 'date-time', starting.format()],
      ['dtend', {}, 'date-time', ending.format()],
      ['summary', {}, 'text', summary],
      ['description', {}, 'text', description],
      [
        'rrule',
        {},
        'recur',
        {
          freq: until ? 'DAILY' : 'WEEKLY',
          [until ? 'until' : 'byday']: until ? until.format() : day.substring(0, 2).toUpperCase(),
        },
      ],
    ]

    const decorations = Object.keys(properties).reduce(
      (store, key) => [...store, [key, {}, 'unknown', properties[key]]],
      []
    )

    if (holiday || (directed && !until)) details = details.slice(0, details.length - 1)
    if (0 < decorations.length) details = details.concat(decorations)

    const ical = formatter.stringify(['vevent', details, []])

    return { ...attributes, directed, ical, kind: holiday ? HOLIDAY_KIND : SCHEDULE_KIND }
  }

  const initialize = (day, alignable = false) => {
    const id = uuid()
    const stamp = dayjs.utc()

    const index = dayjs.weekdays().indexOf(day)
    const offset = stamp.day() < index ? -7 : 0

    const last = dayjs.utc().weekday(index + offset)
    const starting = last.clone().hour(9).minute(0).second(0)
    const ending = last.clone().hour(17).minute(0).second(0)

    const summary = ''
    const description = ''

    const properties = alignable ? { 'x-krow-calendar-alignment-id': uuid() } : {}

    return {
      id,
      summary,
      description,
      starting,
      ending,
      stamp,
      properties,
      persisted: false,
      dirty: true,
      ...defaults,
    }
  }

  return { parse, compile, initialize }
})()

const preoccupation = (() => {
  const defaults = [].reduce((store, [property, value]) => ({ ...store, [property]: value }), {})

  const compile = ({
    id,
    stamp,
    starting,
    ending,
    summary,
    description,
    persisted,
    properties = {},
    repeat = {},
    recurring = false,
    ...attributes
  }) => {
    const day = dayjs.weekdays()[starting.day()]

    const recur =
      Object.keys(repeat).length === 0
        ? { freq: 'WEEKLY', byday: day.substring(0, 2).toUpperCase() }
        : {
            freq: 'WEEKLY',
            byday: day.substring(0, 2).toUpperCase(),
            until: repeat.until.utc().format(),
          }

    let details = [
      ['uid', {}, 'text', id],
      ['dtstamp', {}, 'date-time', stamp.format()],
      ['dtstart', {}, 'date-time', starting.utc().format()],
      ['dtend', {}, 'date-time', ending.utc().format()],
      ['summary', {}, 'text', summary],
      ['description', {}, 'text', description],
      ['rrule', {}, 'recur', recur],
    ]

    const decorations = Object.keys(properties).reduce(
      (store, key) => [...store, [key, {}, 'unknown', properties[key]]],
      []
    )

    if (!recurring) details = details.slice(0, details.length - 1)
    if (0 < decorations.length) details = details.concat(decorations)

    const ical = formatter.stringify(['vevent', details, []])

    return { ...attributes, ical }
  }

  const initialize = (starting, ending, properties = {}) => {
    const id = uuid()
    const stamp = dayjs.utc()

    const summary = 'Busy'
    const description = ''

    return {
      id,
      summary,
      description,
      starting,
      ending,
      stamp,
      properties,
      persisted: false,
      ...defaults,
    }
  }

  const consolidate = (reservations) => {
    const sorted = reservations.sort((first, second) =>
      first.starting.isBefore(second.starting) ? -1 : 1
    )
    const groupings = new Map()

    for (let i = 0; i < sorted.length; i++) {
      const examining = sorted[i]
      const keys = groupings.keys()
      const existing = Array.from(keys).find((key) =>
        key.starting.isSame(examining.starting, 'day')
      )

      if (existing) {
        const group = groupings.get(existing)
        const last = group[group.length - 1]

        if (last.ending.isSame(examining.starting)) {
          groupings.set(existing, [...group, examining])
        } else {
          groupings.set(examining, [examining])
        }
      } else {
        groupings.set(examining, [examining])
      }
    }

    return Array.from(groupings.values())
  }

  return { compile, initialize, consolidate }
})()

export {
  schedule,
  preoccupation,
  calendar,
  summarize,
  equality,
  KROW_RESERVATION_TYPE_DECORATOR,
  KROW_RESERVATION_ID_DECORATOR,
  KROW_RESERVATION_DURATION_DECORATOR,
  KROW_RESERVATION_STATE_DECORATOR,
  KROW_INTERVIEW_TYPE,
  KROW_ELAPSED_TYPE,
  KROW_SCHEDULE_TYPE,
  KROW_PREOCCUPATION_TYPE,
  KROW_INTERVIEW_APPLICANT_PHONE,
  KROW_INTERVIEW_CHECKED_IN_DECORATOR,
}
