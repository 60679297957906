import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Autocomplete, TextField, CircularProgress, Box, Checkbox } from '@mui/material'
import { LocalityScope, OrganizationScope, RegionScope } from 'store/store.types'
import { TLocationDocument, useListLocationsQuery } from 'store/locations/locations.api'
import { useDebounce } from 'use-debounce'
import { ListBox } from 'shared/components'
import { useGetMeQuery } from 'store/me/me.api'

export type KrowLocationAutocompleteProperties = {
  setValue: (attribute: any, value: any, location: TLocationDocument | TLocationDocument[]) => void
  defaultValue: TLocationDocument | null
  attribute: string
  error?: { type?: string; message?: string }
  multiple?: boolean
  disabled?: boolean
  scoped?: boolean
  filter?: any
  page?: any
}

export default function KrowLocationAutocomplete(props: KrowLocationAutocompleteProperties) {
  const { organizationId, regionId, localityId } = useParams<
    OrganizationScope & LocalityScope & RegionScope
  >()
  const { multiple = false, disabled, scoped = false, filter, page, setValue, defaultValue } = props
  const [open, setOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [selected, setSelected] = useState<TLocationDocument | TLocationDocument[] | null>(
    defaultValue ? defaultValue : multiple ? [] : null
  )
  const [debounced] = useDebounce(search, 400)

  const { data: { data: actor } = { data: null }, isLoading: actorLoading } = useGetMeQuery()

  const isOrgMember = actor?.type === 'organization_members'

  const [_entityType, entityId] = regionId
    ? ['regions', regionId]
    : ['organizations', organizationId]
  const entityType = _entityType as 'regions' | 'organizations'
  const { data: { data: locations } = { data: [] }, isLoading } = useListLocationsQuery(
    {
      entityType,
      entityId,
      search: debounced,
      filter: { ...filter, archived: isOrgMember ? false : undefined },
      page,
    },
    { skip: actorLoading }
  )

  useEffect(() => {
    if (!isLoading && (regionId || localityId)) {
      setSelected(locations)
      setValue(null, null, locations)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, locations, regionId, localityId])

  return (
    <Autocomplete
      disableCloseOnSelect={multiple}
      multiple={multiple}
      value={selected}
      disabled={disabled}
      sx={{ width: '100%' }}
      options={locations}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false)
        setSearch('')
      }}
      limitTags={multiple ? 5 : -1}
      ListboxComponent={multiple ? ListBox : undefined}
      ListboxProps={{
        selected,
        setSelected: (locs) => {
          setSelected(locs)
          setValue(null, null, locs)
        },
        options: locations,
      }}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Location"
          variant={scoped ? 'outlined' : 'standard'}
          InputProps={
            scoped
              ? { ...params.InputProps }
              : {
                  ...params.InputProps,
                  endAdornment: (
                    <>{isLoading ? <CircularProgress color="inherit" size={20} /> : null}</>
                  ),
                }
          }
          onChange={(event) => setSearch(event.target.value)}
          error={props?.error?.type === 'error'}
          helperText={props?.error?.message}
        />
      )}
      renderOption={
        scoped && selected
          ? (params, option) => {
              return (
                <Box
                  {...params}
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                    fontSize: theme.typography.body2.fontSize,
                  })}
                >
                  <Checkbox
                    checked={
                      multiple
                        ? !!selected.find((item) => item.id === option.id)
                        : selected?.id === option.id
                    }
                  />
                  {option.name}
                </Box>
              )
            }
          : undefined
      }
      onChange={(_, selected) => {
        if (null === selected || 'undefined' === typeof selected) return
        setSelected(selected)

        setValue(props?.attribute, selected?.id, selected)
      }}
    />
  )
}
