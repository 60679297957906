// @ts-nocheck

import axios from 'util/axios'
import { PositionResponse, Position, Attributes } from './index'
import { organizationId, locationId } from '../path'

const toResourceModel = (position: Partial<Position>) => ({
  attributes: {
    name: position.name,
    description: position.description,
    external_id: position.external_id,
  },
})

export async function get() {
  return axios
    .get<{ data: PositionResponse[] }>(`/organizations/${organizationId()}/positions`, {
      params: { location_id: locationId() },
    })
    .then((response) => response?.data?.data)
}

export async function getOne(id: string) {
  return axios
    .get<{ data: PositionResponse }>(`/organizations/${organizationId()}/positions/${id}`)
    .then((response) => response?.data?.data)
}

export async function update(position: Partial<Position>) {
  return axios
    .patch<{ data: PositionResponse }>(
      `/organizations/${organizationId()}/positions/${position.id}`,
      { data: toResourceModel(position) }
    )
    .then((response) => response?.data?.data)
}

export async function create(attributes: Partial<Attributes>) {
  return axios
    .post<{ data: PositionResponse }>(`/organizations/${organizationId()}/positions`, {
      data: { attributes },
    })
    .then((response) => response?.data?.data)
}

export async function destroy(id: string) {
  return axios
    .delete<{ data: PositionResponse }>(`/organizations/${organizationId()}/positions/${id}`)
    .then((response) => response?.data?.data)
}
