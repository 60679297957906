import { useState, useEffect } from 'react'
import {
  useRevokeMutation,
  useGetAuthenticationQuery,
  useUpdateAuthenticationMutation,
} from 'store/authentication/authentication.api'
import { Dialog, DialogActions, DialogTitle, Typography, DialogContent } from '@mui/material'
import { LoadingButton } from '@mui/lab'

function AuthenticationExpiringDialog() {
  const [expiresAt, setExpiresAt] = useState(null)
  const [open, setOpen] = useState(false)
  const [updateExpiresAt] = useUpdateAuthenticationMutation()
  const [logout] = useRevokeMutation()
  const {
    data: auth,
    isFetching,
    isLoading,
  } = useGetAuthenticationQuery(null, { refetchOnMountOrArgChange: true })
  const expirycheck = new Worker('/expirycheck.js')
  const [authentication, setAuthentication] = useState(auth)

  expirycheck.onmessage = ({ data }) => {
    const { expired, expiringSoon } = data

    if (expired) {
      logoutAndRedirect()
    }
    setOpen(expiringSoon)
  }

  const MINUTE_MS = 60000

  useEffect(() => {
    setAuthentication(auth)
  }, [auth])

  useEffect(() => {
    if (authentication) {
      setExpiresAt(authentication.data.attributes.expires_at)
      expirycheck.postMessage({
        expiresAt: authentication.data.attributes.expires_at,
        minutes: 5,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authentication])

  useEffect(() => {
    const interval = setInterval(() => {
      expirycheck.postMessage({
        expiresAt,
        minutes: 5,
      })
    }, MINUTE_MS / 10)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiresAt])

  const logoutAndRedirect = async () => {
    await logout()
    setOpen(false)
    window.location.reload()
  }

  return (
    <Dialog open={open && !isFetching && !isLoading} fullWidth maxWidth="sm" onClose={() => {}}>
      <DialogTitle>
        <Typography variant="h3">Your session is ending soon</Typography>
      </DialogTitle>
      <DialogContent dividers>Would you like to continue this session?</DialogContent>
      <DialogActions>
        <LoadingButton
          loading={false}
          variant="contained"
          color="primary"
          onClick={async () => {
            const { data: newAuth } = await updateExpiresAt(null)
            if (newAuth) {
              setAuthentication(newAuth)
            }
            setOpen(false)
          }}
        >
          Continue
        </LoadingButton>
        <LoadingButton
          onClick={() => {
            logoutAndRedirect()
          }}
          loading={false}
          variant="contained"
          color="primary"
        >
          Logout
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default AuthenticationExpiringDialog
