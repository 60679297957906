import { SerializedError } from '@reduxjs/toolkit'
import { BaseQueryFn } from '@reduxjs/toolkit/query'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'util/axios'
import { Meta } from './store.types'

interface ErrorItem {
  code: string
  detail: string
  source: {
    pointer: string
  }
  status: number
}

interface ErrorResponse {
  errors: ErrorItem[]
}

export interface HandledQueryError {
  status: number | undefined
  data: ErrorItem[]
}

export const axiosBaseQuery =
  <T extends unknown>(): BaseQueryFn<AxiosRequestConfig & { body?: any }, T, HandledQueryError> =>
  async ({ url, method, body, ...rest }) => {
    try {
      const result: AxiosResponse<any> = await axios({ url, method, ...rest, data: body })
      return { data: result?.data, meta: result?.data?.meta }
    } catch (axiosError) {
      let err = axiosError as AxiosError<ErrorResponse>
      return {
        error: { status: err.response?.status, data: err.response?.data?.errors || [] },
      }
    }
  }

type NestedParams = Record<string, string | number | string[] | object> | object
type FlatParams = Record<string, string | number | string[]>

export const encodeNestedParams = (baseKey: string, param: NestedParams = {}): FlatParams =>
  Object.entries(param).reduce<FlatParams>((aggregate, [key, value]) => {
    const combinerKey = `${baseKey}[${key}]`

    if (typeof value === 'object' && !Array.isArray(value)) {
      return {
        ...encodeNestedParams(combinerKey, value),
        ...aggregate,
      }
    }

    return { ...aggregate, [combinerKey]: value }
  }, {})

export function providesList<R extends { id: string | number }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T
) {
  return resultsWithIds
    ? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
    : [{ type: tagType, id: 'LIST' }]
}

export function providesIncluded<R extends { id: string | number; type: string }[]>(
  resultsWithIds: R | undefined
) {
  return resultsWithIds ? [...resultsWithIds.map(({ id, type }) => ({ type, id }))] : []
}

export function isHandledQueryError(
  error: HandledQueryError | SerializedError | undefined
): error is HandledQueryError {
  return (error as HandledQueryError)?.data !== undefined
}

export const defaultData = Object.assign([], { meta: {} as Meta })
