import React from 'react'
import TextField from '@mui/material/TextField'
import BaseAutocomplete, { AutocompleteProps as BaseProps } from '@mui/material/Autocomplete'
import { useStyles } from './Autocomplete.styles'

export type Option = { name: string; value: string }

type AutocompletePropsExtended = {
  label: string
  helperText?: string | false
  error?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
}
export type AutocompleteProps = Omit<BaseProps<Option, false, true, false>, 'renderInput'> &
  AutocompletePropsExtended

function Autocomplete(props: AutocompleteProps) {
  const { label, helperText, error, variant, ...rest } = props
  const classes = useStyles()

  return (
    <BaseAutocomplete
      classes={{ popper: classes.popper }}
      className={classes.root}
      disableClearable
      renderInput={(params: any) => (
        <TextField
          {...params}
          helperText={helperText}
          error={error}
          label={label}
          variant={variant || 'outlined'}
          fullWidth
        />
      )}
      {...rest}
    />
  )
}

export default React.memo(Autocomplete)
