import NumberFormat from 'react-number-format'

interface PhoneNumberTextProps {
  phoneNumber: string
  color?: string
}

export function PhoneNumberText(props: PhoneNumberTextProps) {
  const { phoneNumber, color, ...other } = props

  return (
    <NumberFormat
      {...other}
      style={{ color: color }}
      value={phoneNumber}
      displayType={'text'}
      format="(###) ###-####"
    />
  )
}
