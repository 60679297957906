import React from 'react'

import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import { TransitionProps } from '@mui/material/transitions'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import { useDeleteLocationMutation } from 'store/locations/locations.api'
import { DialogTitle } from '../DialogTitle'
import Slide from '@mui/material/Slide'
import { useDeletePositionMutation } from 'store/positions/position.api'
import { useDeleteRegionMutation } from 'store/regions/regions.api'
import { useDeleteMemberMutation } from 'store/members/members.api'
import { useDeleteAccountManagerMutation } from 'store/managers/managers.api'
import { useForm } from 'react-hook-form'
import groupBy from 'lodash/groupBy'
import { useAppDispatch } from 'shared/hooks/redux'
import { addSnack } from 'store/snacks'

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  const { children, ...rest } = props
  return (
    <Slide
      direction="down"
      ref={ref}
      {...rest}
      children={children as React.ReactElement<any, any>}
    />
  )
})

interface ConfirmationDialogProps {
  entityIds: string[]
  children?: React.ReactNode
  open: boolean
  onClose: () => void
  title: string
  description: string
  useMutation:
    | typeof useDeleteLocationMutation
    | typeof useDeletePositionMutation
    | typeof useDeleteRegionMutation
    | typeof useDeleteMemberMutation
    | typeof useDeleteAccountManagerMutation
}

function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { open, onClose, title, description, useMutation, entityIds } = props
  const [destroy] = useMutation()
  const dispatch = useAppDispatch()
  const { handleSubmit, formState } = useForm()
  const onSubmit = handleSubmit(async () => {
    const result = await Promise.allSettled(entityIds.map((id) => destroy(id).unwrap()))
    const { fulfilled = [], rejected = [] } = groupBy(result, 'status')
    if (rejected.length) {
      dispatch(
        addSnack({
          severity: 'warning',
          message: `${fulfilled.length} item(s) deleted. ${rejected.length} failed.`,
        })
      )
    } else {
      dispatch(
        addSnack({
          severity: 'success',
          message: `${fulfilled.length} item(s) deleted.`,
        })
      )
    }
    onClose()
  })

  return (
    <Dialog
      data-cy="confirmation-dialog"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="confirmation-title"
      aria-describedby="confirmation_dialog"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle onClose={onClose}>
        <Typography variant="h3" id="organization-title">
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent dividers>
        <Typography>{description}</Typography>
        {props.children}
      </DialogContent>

      <DialogActions
        sx={{
          py: 2,
        }}
      >
        <Button autoFocus color="primary" variant="outlined" onClick={onClose} size="small">
          Cancel
        </Button>
        <LoadingButton
          loading={formState.isSubmitting}
          onClick={onSubmit}
          variant="contained"
          color="primary"
          size="small"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
