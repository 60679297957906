import { api } from '../store.api'
import axios from 'util/axios'

export interface AuthorizationBody {
  qualifier: string
  password: string
}

export interface ConfirmationBody {
  id: string
  password: string
  password_confirmation: string
}

export interface Authorization {
  type: string
  id: string
  attributes: {
    token: string
    expires_at: string
    created_at: string
    updated_at: string
  }
}

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAuthentication: build.query<Authorization, null>({
      query() {
        return {
          method: 'GET',
          url: `me/authentication`,
        }
      },
    }),
    updateAuthentication: build.mutation<{}, null>({
      query() {
        return {
          method: 'PATCH',
          url: `me/authentications`,
        }
      },
    }),
    authenticate: build.mutation<{ data: Authorization }, AuthorizationBody>({
      query(attributes) {
        return {
          method: 'POST',
          url: `authentications`,
          body: {
            data: {
              attributes,
            },
          },
        }
      },
      invalidatesTags: (result) => {
        if (result) {
          ;(axios.defaults.headers as any)[
            'Authorization'
          ] = `Bearer ${result?.data?.attributes?.token}`
          window.localStorage.setItem('access', JSON.stringify(result.data))
          return ['Me']
        }
        return []
      },
    }),
    confirmAccount: build.mutation<{ data: Authorization }, ConfirmationBody>({
      query(data) {
        const { id, ...attributes } = data
        return {
          method: 'PATCH',
          url: `confirmations/${id}`,
          body: {
            data: {
              attributes,
            },
          },
        }
      },
      invalidatesTags: (result) => {
        if (result) {
          ;(axios.defaults.headers as any)[
            'Authorization'
          ] = `Bearer ${result?.data?.attributes?.token}`
          window.localStorage.setItem('access', JSON.stringify(result?.data))
          return ['Me']
        }
        return []
      },
    }),
    recoverAccount: build.mutation<{ data: Authorization }, ConfirmationBody>({
      query(data) {
        const { id, ...attributes } = data
        return {
          method: 'PATCH',
          url: `recoveries/${id}`,
          body: {
            data: {
              attributes,
            },
          },
        }
      },
      invalidatesTags: (result) => {
        if (result) {
          ;(axios.defaults.headers as any)[
            'Authorization'
          ] = `Bearer ${result?.data?.attributes?.token}`
          window.localStorage.setItem('access', JSON.stringify(result?.data))
          return ['Me']
        }
        return []
      },
    }),
    revoke: build.mutation<Authorization, void>({
      query() {
        return {
          method: 'DELETE',
          url: `authentications`,
        }
      },
    }),
    recovery: build.mutation<void, { qualifier: string }>({
      query(attributes) {
        return {
          method: 'POST',
          url: `recoveries`,
          body: {
            data: {
              attributes,
            },
          },
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useAuthenticateMutation,
  useRevokeMutation,
  useConfirmAccountMutation,
  useRecoveryMutation,
  useRecoverAccountMutation,
  useUpdateAuthenticationMutation,
  useGetAuthenticationQuery,
} = extendedApi
