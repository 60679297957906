import axios from 'util/axios'
import { List, StatisticNames, Statistics } from './index'
import { organizationId, locationId } from '../path'

export async function get() {
  return axios
    .get<{ data: List }>(`/organizations/${organizationId()}/statistics`)
    .then((response) => response?.data?.data)
}

export async function getOne(name: StatisticNames) {
  return axios
    .get<{ data: Statistics }>(`/organizations/${organizationId()}/statistics/${name}`, {
      params: {
        location_id: locationId(),
      },
    })
    .then((response) => response?.data?.data)
}

export async function getLocationStatistic(name: StatisticNames, location_id: string) {
  return axios
    .get<{ data: Statistics }>(`/organizations/${organizationId()}/statistics/${name}`, {
      params: {
        location_id,
      },
    })
    .then((response) => response?.data?.data)
}
