import React, { useState, useMemo } from 'react'
import { useDebounce } from 'use-debounce'
import { ControllerRenderProps, ControllerFieldState, UseFormStateReturn } from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { useListAreasQuery } from 'store/country/country.api'

export type FormCountryAutocompleteProperties = {
  field: ControllerRenderProps<any, string>
  fieldState: ControllerFieldState
  formState: UseFormStateReturn<any>
  country?: { areaType: string; id: string }
  variant: 'standard' | 'filled' | 'outlined' | undefined
}

export default function FormAreaAutocomplete({
  field,
  fieldState,
  country,
  variant = 'outlined',
}: FormCountryAutocompleteProperties) {
  const { onChange, ...rest } = field
  const [_search, _setSearch] = useState<string>('')
  const [search] = useDebounce(_search, 400)

  const label = useMemo(
    () => (country?.areaType ? country?.areaType : 'Select a Country'),
    [country]
  )

  const { data = { data: [] }, isLoading } = useListAreasQuery(
    { countryId: country?.id as string, search },
    { skip: !country?.id }
  )

  const options = useMemo(() => {
    return data?.data?.map((area) => ({ name: area.attributes.name, id: area.id })) || []
  }, [data?.data])

  return (
    <Autocomplete
      {...rest}
      defaultValue=""
      fullWidth
      sx={{ marginBottom: 3 }}
      value={field.value || null}
      options={options}
      getOptionLabel={(option) => option?.name || ''}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      loading={isLoading}
      onChange={(event, option) => {
        onChange(option)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              </React.Fragment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          onChange={(event) => _setSearch(event.target.value)}
          error={!!fieldState.error?.message}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}
