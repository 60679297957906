import MuiDialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useStyles } from './DialogTitle.styles'

export interface DialogTitleProps {
  children: React.ReactNode
  onClose?: () => void
}

const DialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles()
  const { children, onClose, ...other } = props
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Box alignSelf="center">{children}</Box>

      {onClose && (
        <Box ml={2}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </MuiDialogTitle>
  )
}

export default DialogTitle
