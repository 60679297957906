import React from 'react'
import { createTheme, alpha } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'
import { sofiaLight, sofiaRegular, sofiaBold } from 'assets/fonts'
import '@mui/lab/themeAugmentation'

const grey = {
  100: '#EDEEF4',
  200: '#DBE2E7',
  400: '#95A1AC',
  500: '#8B97A2',
  600: '#57636C',
  700: '#384550',
  800: '#15212B',
  900: '#141419',
  A100: '#090f13',
}

const blue = {
  50: '#05062C',
  100: '#2E8AFF',
  200: '#3138BB',
}

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props
  // Map href (Material-UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />
})

// candidate experience theme deprecated
export const cxTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FFF',
    },
    success: {
      main: '#EBBF12',
    },
    background: {
      paper: grey[100],
      default: '#FFF',
    },
    text: {
      primary: '#000000',
      secondary: '#57636C',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Sofia, Arial',
    h1: {
      fontSize: 56,
      fontWeight: 700,
    },
    h2: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: '34px',
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 20,
    },
    body1: {
      fontSize: 18,
    },
    body2: {
      fontSize: 14,
    },
  },
})

export const theme = createTheme({
  questionnaire: {
    jump: {
      main: blue[200],
    },
    remove: {
      main: '#E64C4C',
    },
    exit: {
      main: blue[100],
    },
  },
  palette: {
    primary: {
      main: blue[200],
    },
    secondary: {
      main: '#A3A3BA',
      contrastText: '#FFF',
    },
    accent: {
      main: '#4AC49F',
      contrastText: '#fff',
    },
    error: {
      main: '#E62A72',
    },
    info: {
      main: blue[100],
    },
    salmon: {
      main: '#FF7D6F',
      contrastText: '#fff',
    },
    warning: {
      main: '#FF961C',
    },
    text: {
      primary: '#1E2429',
      secondary: '#5E7081',
    },
    background: {
      paper: '#FFF',
      default: grey[100],
    },
    grey,
    blue,
  },
  typography: {
    fontFamily: 'Sofia, Arial',
    h1: {
      fontSize: 56,
      fontWeight: 700,
    },
    h2: {
      fontSize: 36,
      fontWeight: 700,
      lineHeight: 1.1,
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 20,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ${sofiaLight}
        ${sofiaRegular}
        ${sofiaBold}
      `,
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-columnHeaderWrapper ': {
            backgroundColor: grey[100],
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '11px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            whiteSpace: 'break-spaces',
            lineHeight: '1.1',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            '& .MuiSvgIcon-root': {
              height: '12px',
              width: '12px',
              color: 'black',
            },
          },
          '& .MuiDataGrid-cell--withRenderer, .MuiDataGrid-cell--withRenderer > a': {
            whiteSpace: 'break-spaces !important',
            lineHeight: '1.1',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
          '& [data-field="email_address"]': {
            wordBreak: 'break-all',
          },
          '& .MuiDataGrid-row--dynamicHeight': {
            minHeight: '68px !important',
          },
          '& .MuiDataGrid-actionsCell': {
            '& .Mui-disabled': {
              backgroundColor: grey[100],
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#8B97A2',
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'new' },
          style: {
            color: '#29A475',
            backgroundColor: '#C5EBDC',
          },
        },
        {
          props: { variant: 'pending' },
          style: {
            color: '#FFF',
            backgroundColor: blue[100],
          },
        },
      ],
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderTop: `1px solid #DBE2E7`,
          '&:last-child': {
            borderBottom: `1px solid #DBE2E7`,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiDateRangePickerDay: {
      styleOverrides: {
        root: ({
          theme,
          ownerState: { isHighlighting, isStartOfHighlighting, isEndOfHighlighting },
        }) => ({
          ...(isHighlighting && {
            borderRadius: 0,
            backgroundColor: alpha(theme.palette.primary.main, 0.5),
            '&:hover, &:focus': {
              backgroundColor: theme.palette.primary.main,
            },
          }),
          ...(isStartOfHighlighting && {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
          }),
          ...(isEndOfHighlighting && {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
          }),
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '16px',
        },
        outlinedSizeSmall: {
          padding: '8px 12px',
          minWidth: '100px',
        },
        outlinedSizeMedium: {
          padding: '15px 20px',
          minWidth: '100px',
        },
        containedSizeSmall: {
          padding: '8px',
          minWidth: '100px',
        },
        containedSizeMedium: {
          padding: '16px 20px',
          minWidth: '100px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            background: alpha(blue[200], 0.08),
          },
          '&.Mui-selected:hover': {
            background: alpha(blue[200], 0.08),
          },
        },
      },
    },
  },
  calendar: {
    schedule: {
      main: '#F5CF70',
      background: '#F5CF70',
      text: '#384550',
      border: '#000',
    },
    interview: {
      main: '#66A9F9',
      background: '#66A9F9',
      text: '#384550',
      border: '#000',
    },
    preoccupation: {
      main: '#8B97A2',
      background: '#8B97A2',
      text: '#15212B',
      border: '#000',
    },
  },
  shape: {
    borderRadius: 8,
  },
})

theme.shadows[1] = '0 1px 3px 0 rgba(0,0,0,0.15)'
theme.shadows[2] = '0 2px 7px 0 rgba(36,36,36,0.20)'
theme.shadows[3] = ' 0 3px 10px 0 rgba(36,36,36,0.20)'
