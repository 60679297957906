import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import useStyles from './KrowStatusLabel.styles'
import { Tooltip } from 'shared/components'
import { split, join } from 'lodash'

interface KrowStatusLabelProps {
  name: string
  action: string
  textStyles: object
}

const statusActionLabel = (action: string) => {
  switch (action) {
    case 'checked_in':
    case 'hire':
      return 'success'
    case 'pending':
      return 'primary'
    case 'keep_warm':
      return 'warning'
    case 'reject':
      return 'error'
    default:
      return 'default'
  }
}

function KrowStatusLabel({ name, action, textStyles = {} }: KrowStatusLabelProps) {
  const classes = useStyles()

  const statusTextToRender = name ? name.replace(/_/g, ' ') : ''

  return (
    <Tooltip
      title={join(split(action, '_'), ' ')}
      placement="top"
      disableHoverListener={action !== 'checked_in'}
      sx={{ textTransform: 'capitalize' }}
    >
      <Box className={classes.statusLabelWrapper}>
        {action && (
          <Badge
            classes={{ dot: classes.labelDot }}
            color={statusActionLabel(action)}
            variant="dot"
          />
        )}

        <Typography
          variant="body2"
          color="textPrimary"
          sx={[
            {
              textTransform: 'capitalize',
              paddingLeft: action ? 0.5 : undefined,
            },
            textStyles,
          ]}
        >
          {statusTextToRender}
        </Typography>
      </Box>
    </Tooltip>
  )
}

export default KrowStatusLabel
