import React from 'react'
import AdapterDateFns from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { QueryParamProvider } from 'use-query-params'
import store from 'store'
import { Provider } from 'react-redux'
import { StyledEngineProvider } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter, Route } from 'react-router-dom'
import { ApplicationRoutingProvider } from 'shared/hooks/useApplicationRouting'
import { theme } from './theme'
import 'util/dayjs'

interface ProviderProps {
  children?: React.ReactNode | React.ReactNode[]
}

function Providers(props: ProviderProps) {
  return (
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <BrowserRouter basename="/">
          <QueryParamProvider ReactRouterRoute={Route}>
            <ThemeProvider theme={theme}>
              <ApplicationRoutingProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <CssBaseline />
                  {props.children}
                </LocalizationProvider>
              </ApplicationRoutingProvider>
            </ThemeProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </Provider>
    </StyledEngineProvider>
  )
}

export default Providers
