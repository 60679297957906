import axios from 'util/axios'
import { Ping, OrganizationResponse } from './index'
import { Meta } from '../store.types'

export async function get(query: undefined) {
  return axios
    .get<{ data: OrganizationResponse[]; meta: Meta }>('/organizations')
    .then((response) => response?.data)
}

export async function getOne(id: string) {
  return axios.get(`/organizations/${id}`).then((response) => response.data)
}

export async function ping() {
  return axios.get<{ data: Ping }>('/ping').then((response) => response?.data?.data)
}
