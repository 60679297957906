import { Notifier } from '@airbrake/browser'
import runtimeEnv from 'util/runtimeEnv'

const env = runtimeEnv()

let notifier: Notifier | null = null

function getNotifier(): { notify: ({}) => void } {
  if (notifier !== null) {
    return notifier
  }

  const environment = env || process.env || {}
  if (environment.REACT_APP_AIRBRAKE_PROJECT_KEY && environment.REACT_APP_AIRBRAKE_PROJECT_ID) {
    notifier = new Notifier({
      projectId: environment.REACT_APP_AIRBRAKE_PROJECT_ID,
      projectKey: environment.REACT_APP_AIRBRAKE_PROJECT_KEY,
      environment: environment.NODE_ENV,
    })

    return notifier
  }

  return {
    notify: (data) => {
      console.error(data)
    },
  }
}

export default function notifyError(error: Error) {
  getNotifier()?.notify({
    error: error,
    context: {}, // what we can add to context?
    params: {}, // what we can add to params?
  })
}
