import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    statusLabelWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    labelDot: {
      position: 'relative',
      minWidth: '6px',
      minHeight: '6.33px',
      width: '6px',
      height: '6.33px',
      transform: 'none',
      zIndex: 0,
    },
  })
)

export default useStyles
