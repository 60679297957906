import React, { useState, createContext, useContext } from 'react'

export type ApplicationSteps =
  | 'start'
  | 'geo'
  | 'questionnaire'
  | 'collect'
  | 'exit'
  | 'success'
  | 'information'
  | 'thanks'
  | 'congrats'
export type SetApplicationRouting = React.Dispatch<React.SetStateAction<ApplicationSteps>>

const ApplicationRouting = createContext<[ApplicationSteps, SetApplicationRouting]>([
  'start',
  () => {},
])

interface ApplicationRoutingProviderProps {
  children: React.ReactNode
}

export const ApplicationRoutingProvider = (props: ApplicationRoutingProviderProps) => {
  const [state, setStep] = useState<ApplicationSteps>('start')

  return (
    <ApplicationRouting.Provider value={[state, setStep]}>
      {props.children}
    </ApplicationRouting.Provider>
  )
}

export function useApplicationRouting() {
  return useContext(ApplicationRouting)
}
