import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import clsx from 'clsx'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'
import { TransitionProps } from '@mui/material/transitions'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import { PhoneNumberInput, DialogTitle } from 'shared/components'
import { useStyles } from './UserProfileEditDialog.styles'
import { useGetMyProfileQuery, useUpdateMyProfileMutation, IncludedProfile } from 'store/me/me.api'
import { addServerErrors } from 'util/addServerErrors'
import { useAppDispatch } from 'shared/hooks/redux'
import { addSnack } from 'store/snacks'

interface UserProfileEditDialogProps {
  open: boolean
  onClose: () => void
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  const { children, ...rest } = props
  return (
    <Slide
      direction="down"
      ref={ref}
      {...rest}
      children={children as React.ReactElement<any, any>}
    />
  )
})

interface FormValues {
  first_name: string
  last_name: string
  phone_number: string
}

const defaultValues = (profile?: IncludedProfile) => ({
  first_name: profile?.first_name || '',
  last_name: profile?.last_name || '',
  phone_number: profile?.phone_number || '',
})

function UserProfileEditDialog({ open, onClose }: UserProfileEditDialogProps) {
  const classes = useStyles()
  const { data } = useGetMyProfileQuery()
  const [triggerProfileUpdate] = useUpdateMyProfileMutation()
  const dispatch = useAppDispatch()

  const { control, handleSubmit, getValues, formState, setError, reset } = useForm<FormValues>({
    defaultValues: defaultValues(data?.data),
  })

  const values = getValues()

  const onSubmit = handleSubmit(async (data: FormValues) => {
    try {
      await triggerProfileUpdate(data).unwrap()
      dispatch(addSnack({ message: 'Personal Information Updated.' }))
      onClose()
    } catch (e: any) {
      const errors = e?.data
      const isApiError = Array.isArray(errors) && errors.length
      const keys = Object.keys(data) as (keyof FormValues)[]
      if (isApiError && addServerErrors<FormValues>(keys, errors, setError)) {
        return
      }
    }
  })

  useEffect(() => {
    reset(defaultValues(data?.data))
  }, [open, reset, data])

  return (
    <Dialog open={open} TransitionComponent={Transition} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle onClose={onClose}>
        <Typography variant="h3" id="organization-title">
          Edit Profile
        </Typography>
        <Typography variant="body1" color="textSecondary" id="organization-description">
          Enter your personal information
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box mt={2}>
          <Box component="form" onSubmit={onSubmit} id="profile_form">
            <Box display="flex" justifyContent="space-between">
              <Controller
                name="first_name"
                control={control}
                defaultValue={values.first_name}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    className={clsx(classes.row)}
                    inputProps={{
                      'data-cy': 'first_name',
                    }}
                    fullWidth
                    label="First Name"
                    type="text"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                defaultValue={values.last_name}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    inputProps={{
                      'data-cy': 'last_name',
                    }}
                    className={clsx(classes.row)}
                    fullWidth
                    label="Last Name"
                    type="text"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
            <Controller
              name="phone_number"
              control={control}
              defaultValue={values.phone_number}
              rules={{
                minLength: 10,
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    InputLabelProps={{
                      shrink: !!field.value ? true : undefined,
                    }}
                    {...field}
                    inputProps={{
                      'data-cy': 'phone_number',
                    }}
                    InputProps={{
                      inputComponent: PhoneNumberInput as any,
                    }}
                    className={classes.margin}
                    fullWidth
                    label="Phone Number"
                    type="phone"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )
              }}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions className={classes.footer}>
        <LoadingButton
          loading={formState.isSubmitting}
          variant="contained"
          color="primary"
          type="submit"
          form="profile_form"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default UserProfileEditDialog
