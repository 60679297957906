import SofiaLight from './sofiapro-light-webfont.woff2'
import SofiaMedium from './sofiapro-medium-webfont.woff2'
import SofiaBold from './sofiapro-bold-webfont.woff2'

const sofiaLight = `
  @font-face {
    font-family: 'Sofia';
    font-style: 'normal';
    font-display: 'swap';
    font-weight: 300;
    src: local('Sofia'), local('Sofia-regular'), url(${SofiaLight}) format('woff2');
  }
`

const sofiaRegular = `
  @font-face {
    font-family: 'Sofia';
    font-style: 'normal';
    font-display: 'swap';
    font-weight: 400;
    src: local('Sofia'), local('Sofia-regular'), url(${SofiaMedium}) format('woff2');
  }
`

const sofiaBold = `
  @font-face {
    font-family: 'Sofia';
    font-style: 'normal';
    font-display: 'swap';
    font-weight: 700;
    src: local('Sofia'), local('Sofia-regular'), url(${SofiaBold}) format('woff2');
  }
`

export { sofiaLight, sofiaRegular, sofiaBold }
