import { createSlice, SliceCaseReducers, createAsyncThunk } from '@reduxjs/toolkit'
import { Operation } from 'fast-json-patch'
import { locationQuestionnaire } from '../questionnaires'
import * as QuestionService from './question.service'

type Choice = { label: string }
interface Attributes<A, K> {
  title: string
  description: string
  required?: boolean
  visible: boolean
  image_url?: string
  answers: A[]
  kind: K
}

interface PriorityThreeAttributes {
  title: string
  description: string
  image_url?: string
  visible: boolean
  kind: 'priority_three'
  priorities: Choice[]
  items: Choice[]
}

interface Question {
  id: string
}

export interface Answer {
  label: string
  description: string
  image_url?: string
  id: string
  exit_condition: boolean
  patch: Operation[]
}

export type RankedListQuestion = Question & Attributes<Answer, 'ranking'>
export type RankedListQuestionResponse = Question & {
  attributes: Attributes<Answer, 'ranking'>
}

export type MultipleChoiceQuestion = Question & Attributes<Answer, 'multiple_choice'>
export type MultipleChoiceQuestionResponse = Question & {
  attributes: Attributes<Answer, 'multiple_choice'>
}

export type MultipleSelectQuestion = Question & Attributes<Answer, 'multiple_select'>
export type MultipleSelectQuestionResponse = Question & {
  attributes: Attributes<Answer, 'multiple_select'>
}

export type PriorityQuestion = Question & PriorityThreeAttributes
type PriorityQuestionResponse = Question & {
  attributes: PriorityThreeAttributes
}

export type Questions =
  | MultipleChoiceQuestion
  | PriorityQuestion
  | MultipleSelectQuestion
  | RankedListQuestion
export type QuestionsResponse =
  | MultipleChoiceQuestionResponse
  | PriorityQuestionResponse
  | MultipleSelectQuestionResponse
  | RankedListQuestionResponse

export type QuestionState = {
  data: Questions[]
}

export type BuilderQuestions = MultipleChoiceQuestion | MultipleSelectQuestion

export const locationQuestions = createAsyncThunk(
  'questions/relationship',
  async (positionId: string | undefined, ctx) => {
    return QuestionService.relationship(positionId)
  }
)

const questionsState = createSlice<QuestionState, SliceCaseReducers<QuestionState>>({
  name: 'questions',
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(locationQuestions.fulfilled, (state, action) => {
      state.data = action.payload.map((question) => {
        const { attributes, ...rest } = question
        return {
          ...rest,
          ...attributes,
        }
      })
    })

    builder.addCase(locationQuestionnaire.fulfilled, (state, action) => {
      state.data = action.payload.questions
    })
  },
})

export function isPatchable(
  question: Questions
): question is MultipleChoiceQuestion | MultipleSelectQuestion {
  return (question as MultipleChoiceQuestion).answers !== undefined
}

export default questionsState
