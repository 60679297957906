import { Dialog, DialogActions, DialogContent, Button, Grid } from '@mui/material'
import { ReactComponent as WarningImage } from 'assets/icons/exclamation.svg'

function RouterPrompt({ children, cancelText, confirmText, showPrompt, onCancel, handleConfirm }) {
  return (
    <Dialog open={showPrompt} keepMounted onClose={onCancel} fullWidth>
      <DialogContent>
        <Grid container justifyContent="center" alignItems={'center'} mt={4} spacing={3}>
          <Grid item>
            <WarningImage />
          </Grid>

          <Grid item color="#95A1AC">
            {children}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-evenly" my={4}>
          <Grid item>
            <Button variant="outlined" onClick={onCancel}>
              {cancelText}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleConfirm}>
              {confirmText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default RouterPrompt
