import { createSlice, SliceCaseReducers, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { Theme } from '@mui/material/styles'
import { RootState } from 'store'
import { pagination, Pagination, ListQueryParams } from '../store.types'
import * as ResponseService from './response.service'

interface MultipleChoiceAnswer {
  question_id: string
  label: string
}

interface MultipleSelectAnswer extends MultipleChoiceAnswer {}

interface RankedListAnswer extends MultipleChoiceAnswer {
  rank: number
}

interface PriorityThreeAnswer {
  question_id: string
  priority: string
  item: string
}

type Answers = MultipleChoiceAnswer | PriorityThreeAnswer | MultipleSelectAnswer | RankedListAnswer

export function isMultipleChoiceAnswer(answer: Answers): answer is MultipleChoiceAnswer {
  return (answer as MultipleChoiceAnswer).label !== undefined
}

export function isRankedListAnswer(answer: Answers): answer is RankedListAnswer {
  return (answer as RankedListAnswer).rank !== undefined
}

export function isPriorityThreeAnswer(answer: Answers): answer is PriorityThreeAnswer {
  return (answer as PriorityThreeAnswer).priority !== undefined
}

export interface Attributes {
  location_id: string
  questionnaire_id: string
  opening_id: string
  position_id: string
  availability_id: string | null
  first_name?: string
  last_name?: string
  phone_number?: string
  email_address?: string
  receive_text?: boolean
  fields: {
    first_name?: string
    last_name?: string
    phone_number?: string
    email_address?: string
    receive_text?: boolean
    [key: string]: string | boolean | undefined
  }
  status: 'scheduled' | 'unscheduled' | 'pending' | 'rejected' | 'accepted'
  answers: Answers[]
  reason: string
  created_at: string
  updated_at: string
}

type BaseResponse = { id: string; type: 'Response' }
export type Response = BaseResponse & Attributes
export type ResponseResource = BaseResponse & { attributes: Attributes }

export interface Params {
  availability_id: string
  opening_id: string
  response_id: string
  location_id: string
  position_id: string
}

export type ResponseState = {
  isLoaded: boolean
  byId: { [key: string]: Response }
  query: Response[]
  application: Partial<Attributes>
  result: string[]
  byPage: {
    [key: string]: string[]
  }
  pagination: Pagination
  currentPageReference: string
}

export const createResponse = createAsyncThunk('response/create', async (_, ctx) => {
  return ResponseService.create((ctx.getState() as RootState).responses.application)
})

export const updateResponse = createAsyncThunk(
  'response/update',
  async (update: Partial<Attributes> & { id: string }, ctx) => {
    const { id, ...attributes } = update
    return ResponseService.update(id, attributes)
  }
)

export const getResponses = createAsyncThunk(
  'response/get',
  async (params: ListQueryParams | undefined, ctx) => {
    return ResponseService.get(params)
  }
)

export const getResponsesWithQuery = createAsyncThunk(
  'response/getWithQuery',
  async (params: Partial<Params>, ctx) => {
    return ResponseService.getWithQuery(params)
  }
)

const responsesState = createSlice<ResponseState, SliceCaseReducers<ResponseState>>({
  name: 'responses',
  initialState: {
    isLoaded: false,
    application: {
      answers: [],
      fields: {},
    },
    byId: {},
    query: [],
    result: [],
    byPage: {},
    pagination,
    currentPageReference: '',
  },
  reducers: {
    setApplication(state, action: PayloadAction<Partial<Attributes>>) {
      Object.assign(state.application, action.payload)
      return state
    },
    addAnswer(state, action: PayloadAction<Answers | Answers[]>) {
      state.application.answers?.push(...[action.payload].flat())
    },
    removeAnswer(state, action: PayloadAction<string>) {
      state.application.answers = state.application.answers?.filter(
        (answer) => answer.question_id !== action.payload
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getResponses.pending, (state, action) => {
      state.isLoaded = false
      state.currentPageReference = JSON.stringify(action.meta.arg)
    })

    builder.addCase(getResponses.fulfilled, (state, action) => {
      const result: string[] = []
      action.payload.data.forEach((response, i) => {
        state.byId[response.id] = { id: response.id, type: response.type, ...response.attributes }
        result.push(response.id)
      })

      state.isLoaded = true
      state.pagination = action.payload.meta.pagination
      state.result = result

      if (action.meta.arg) {
        state.byPage[JSON.stringify(action.meta.arg)] = result
      }
    })

    builder.addCase(getResponses.rejected, (state, action) => {
      state.isLoaded = true
    })

    builder.addCase(createResponse.fulfilled, (state, action) => {
      const { attributes, ...rest } = action.payload
      state.byId[rest.id] = { ...attributes, ...rest }

      if (!state.result.includes(rest.id)) {
        state.result.push(rest.id)
      }
    })
    builder.addCase(getResponsesWithQuery.pending, (state, action) => {
      state.isLoaded = false
    })
    builder.addCase(updateResponse.fulfilled, (state, action) => {
      const { id, type, attributes } = action.payload
      state.byId[action.payload.id] = { id, type, ...attributes }
    })
    builder.addCase(getResponsesWithQuery.fulfilled, (state, action) => {
      state.query = []
      state.isLoaded = true
      action.payload.forEach((response) => {
        const { attributes, ...rest } = response
        state.byId[response.id] = { ...rest, ...attributes }
        state.query.push({ ...rest, ...attributes })
      })
    })
  },
})

export const status = (status: Response['status'], theme: Theme) => {
  if (status === 'accepted') {
    return { color: theme.palette.secondary.main, text: 'Hired' }
  }
  if (status === 'rejected') {
    return { color: theme.palette.error.main, text: 'Rejected' }
  }
  if (status === 'pending') {
    return { color: theme.palette.text.primary, text: 'Pending' }
  }
  if (status === 'scheduled') {
    return { color: theme.palette.text.primary, text: 'Scheduled' }
  }
  return { color: theme.palette.text.primary, text: 'Unscheduled' }
}

export const { setApplication, addAnswer, removeAnswer } = responsesState.actions

export default responsesState
