import { configureStore } from '@reduxjs/toolkit'
import storeRegister from './storeRegister'
import rootReducer from './rootReducer'
import { api } from './store.api'

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
})

storeRegister.store = store

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export default store
