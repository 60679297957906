import NotFound from 'routes/NotFound'
import { useParams } from 'react-router-dom'
import { Scope } from 'store/store.types'
import { useGetOneOrganizationQuery } from 'store/organizations/organizations.api'
import { useGetOneLocationQuery } from 'store/locations/locations.api'
import { isHandledQueryError } from 'store/store.utils'

interface ValidateScopeParamsProps {
  children: JSX.Element
}

function ValidateScopeParams(props: ValidateScopeParamsProps) {
  const { organizationId, localityId } = useParams<Scope>()
  const org = useGetOneOrganizationQuery(organizationId)
  const location = useGetOneLocationQuery(localityId)
  const isLocationNotFound = isHandledQueryError(location.error) && location.error.status === 404
  const isOrganizationNotFound = isHandledQueryError(org.error) && org.error.status === 404

  return isLocationNotFound || isOrganizationNotFound ? <NotFound /> : props.children
}

export default ValidateScopeParams
