import React from 'react'
import cx from 'clsx'
import MatSelect, { SelectProps as MatSelectProps } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useStyles } from './Select.styles'

interface SelectProps extends Omit<MatSelectProps, 'onChange'> {
  options: Option[]
  onChange: (value: string) => void
  startIcon?: boolean
  helperText?: string | boolean | undefined
}

interface Option {
  value: string | number
  name: string
}

function Select(props: SelectProps) {
  const { options, className, error, helperText, value, startIcon, onChange, fullWidth, ...rest } =
    props
  const classes = useStyles()

  return (
    <FormControl className={classes.formControl} variant="outlined" fullWidth={fullWidth}>
      <InputLabel error={error}>{props.label}</InputLabel>
      <MatSelect
        {...rest}
        value={value}
        onChange={(e) => {
          if (typeof e.target.value === 'string') {
            onChange(e.target.value)
          }
        }}
        variant="outlined"
        className={cx(classes.select, className, { [classes.startIcon]: startIcon })}
        autoWidth
        error={error}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </MatSelect>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default Select
