import React from 'react'
import clsx from 'clsx'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'

import { useStyles } from './Dropdown.styles'

interface DropdownProps {
  dataCySelector?: string
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'inherit' | 'primary' | 'secondary' | 'default'
  items: (MenuItemProps & { onClick: () => void })[]
}

function Dropdown(props: DropdownProps) {
  const { dataCySelector } = props
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement | null>(null)

  const handleToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (e: MouseEvent | TouchEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (anchorRef?.current?.contains(e.target as Node)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef?.current?.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div
      className={classes.root}
      data-cy={clsx({
        [`options-trigger-${dataCySelector}`]: dataCySelector,
        'options-trigger': !dataCySelector,
      })}
    >
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        aria-label="options"
        role="button"
        onClick={handleToggle}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  className={classes.list}
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {props.items.map((item, index) => {
                    const { children, onClick, ...rest } = item
                    return (
                      <MenuItem
                        key={index}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          setOpen(false)
                          item?.onClick()
                        }}
                        {...rest}
                      >
                        {item.children}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

Dropdown.defaultProps = {
  variant: 'contained',
  color: 'primary',
}

export default Dropdown
