import { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import Slide, { SlideProps } from '@mui/material/Slide'
import { removeSnack, Snack } from 'store/snacks'

type TransitionProps = Omit<SlideProps, 'direction'>

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />
}

export default function DirectionSnackbar() {
  const dispatch = useAppDispatch()
  const [current, setCurrent] = useState<Snack | null>(null)
  const [open, setOpen] = useState(false)
  const snacks = useAppSelector((state) => state.snacks)

  useEffect(() => {
    const [nextSnack] = snacks
    if (nextSnack && !current) {
      // Set a new snack when we don't have an active one
      setCurrent(nextSnack)
      dispatch(removeSnack(nextSnack))
      setOpen(true)
    } else if (nextSnack && current && open) {
      // Close an active snack when a new one is added
      setOpen(false)
    }
  }, [snacks, current, open, dispatch])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Snackbar
      autoHideDuration={4000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={handleClose}
      TransitionProps={{
        onExited: () => {
          setCurrent(null)
        },
      }}
      open={open}
      TransitionComponent={TransitionUp}
    >
      <Alert onClose={handleClose} severity={current?.severity} sx={{ width: '100%' }}>
        <span style={{ whiteSpace: 'pre-line' }}>{current?.message}</span>
      </Alert>
    </Snackbar>
  )
}
