import React, { useMemo } from 'react'
import { useGetMyProfileQuery } from 'store/me/me.api'
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom'

interface PublicRouteProps extends RouteProps {
  children: React.ReactChild
}

const PublicRoute = ({ children, ...rest }: PublicRouteProps) => {
  const { data: me } = useGetMyProfileQuery()
  const { search } = useLocation()
  const redirect = useMemo(() => new URLSearchParams(search).get('redirect'), [search])

  return (
    <Route {...rest}>
      {!me ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: redirect ? decodeURIComponent(redirect) : '/',
            state: { from: rest.path },
          }}
        />
      )}
    </Route>
  )
}

export default PublicRoute
