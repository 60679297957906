import { api } from '../store.api'
import { providesList } from 'store/store.utils'
import deserialize from 'util/deserialize'

import runtimeEnv from 'util/runtimeEnv'
import { join } from 'lodash'

const env = runtimeEnv()
const baseURL =
  env.REACT_APP_BASE_API_URL || process.env.REACT_APP_BASE_URL || 'http://localhost:3000'

export const applicantsApi = api.injectEndpoints({
  endpoints: (build) => ({
    allApplicants: build.query({
      query({
        version = 'v2',
        filter,
        sort,
        search,
        entityType,
        entityId,
        page = { number: 1, size: 25 },
      }) {
        const params = {
          filter,
          search,
          page,
          sort,
          included: ['applied_opening', 'locality', 'opening', 'opening.position', 'interview'],
        }

        return {
          method: 'GET',
          baseURL: join([baseURL, version], '/'),
          url: `${entityType}/${entityId}/applicants`,
          params: params,
        }
      },
      transformResponse: (response) => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result) => (result ? providesList(result.data, 'Applicant') : []),
    }),
    oneApplicant: build.query({
      query({ candidateId }) {
        return {
          method: 'GET',
          url: `applicants/${candidateId}`,
          params: {
            included: [
              'applied_opening',
              'locality',
              'opening',
              'opening.position',
              'interview',
              'questionnaire',
              'area',
            ],
          },
        }
      },
      transformResponse: (response) => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: ['Applicant', 'Opening'],
    }),
    updateApplicant: build.mutation({
      query({ id, opening_id }) {
        return {
          method: 'PUT',
          url: `applicants/${id}/openings/${opening_id}`,
        }
      },
    }),
    submitApplication: build.mutation({
      query(application) {
        const { responses, retainmentToken, ...rest } = application
        const headers = { 'X-Retainment-Token': retainmentToken }
        return {
          method: 'POST',
          url: `applicants`,
          headers,
          body: {
            data: {
              attributes: {
                ...rest,
                responses,
              },
            },
          },
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useAllApplicantsQuery,
  useOneApplicantQuery,
  useSubmitApplicationMutation,
  useUpdateApplicantMutation,
} = applicantsApi
