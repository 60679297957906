import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: '#ffff',
      boxShadow: theme.shadows[1],
      padding: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1, 0),
      },
    },
    toolbar: {
      justifyContent: 'space-between',
    },
    logo: {
      maxHeight: 36,
      marginRight: theme.spacing(2),
    },
  })
)

export default useStyles
