import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

export function DescendSortIcon() {
  return <ArrowDownwardIcon fontSize="small" />
}
export function AscendSortIcon() {
  return <ArrowUpwardIcon fontSize="small" />
}
export function UnsortedIcon() {
  return <ArrowUpwardIcon color="disabled" fontSize="small" />
}
