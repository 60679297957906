import { isEmpty } from 'lodash'
import runtimeEnvHeroku from '@mars/heroku-js-runtime-env'

const runtimeEnv = () => {
  const env = process.env
  if (env?.REACT_APP_IS_AZURE) {
    console.assert(!isEmpty(env), 'process.env in not defined or empty')
    return env
  } else {
    return runtimeEnvHeroku()
  }
}

export default runtimeEnv
