// @ts-nocheck
import { createSlice, SliceCaseReducers, createAsyncThunk } from '@reduxjs/toolkit'
import { Resource } from '../store.types'
import * as OrganizationService from './organization.service'
import { pagination, Pagination, ListQueryParams } from '../store.types'

export enum Status {
  created = 'created',
  pending = 'pending',
  rejected = 'rejected',
}

interface Attributes {
  website_url: string
  created_at: string
  updated_at: string
  num_regions: number
  num_locations: number
  num_users: number
  num_account_managers: number
  status: Status
  name: string
  subdomain: string
  state: string
  logo: string | null
  universal_position: boolean
  geosmoothing_enabled: boolean
  funnel_landing_body_text: string
  funnel_landing_header_text: string
  onboarding_enabled: boolean
  phone_interview: boolean
  settings: {
    onboarding_enabled: boolean
  }
  geosmoothing_assets?: {
    marker_default?: string
    marker_default_selected?: string
    marker_speedy?: string
    marker_speedy_selected?: string
  }
  theme: any
}

export interface Ping {
  id: string
  type: 'Ping'
  attributes: {
    organization: Organization
    timestamp: string
  }
}

export type BaseOrganization = { id: string; type: 'Organization' }
export type OrganizationResponse = BaseOrganization & Resource<Attributes>
export type Organization = BaseOrganization & Attributes

export type OrganizationState = {
  isLoaded: boolean
  byId: { [key: string]: Organization }
  activeOrganizationId: string
  result: string[]
  byPage: {
    [key: string]: string[]
  }
  pagination: Pagination
  currentPageReference: string
}

export const ping = createAsyncThunk('organizations/ping', async (_, ctx) => {
  return OrganizationService.ping()
})

export const getOrganizations = createAsyncThunk(
  'organizations/get',
  async (query: ListQueryParams | undefined, ctx) => {
    return OrganizationService.get(query)
  }
)

const organizationsSlice = createSlice<OrganizationState, SliceCaseReducers<OrganizationState>>({
  name: 'organizations',
  initialState: {
    isLoaded: false,
    result: [],
    byId: {},
    activeOrganizationId: '',
    byPage: {},
    pagination,
    currentPageReference: '',
  },
  reducers: {
    setOrganizations(state, action) {
      state.byId = action.payload.byId
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ping.fulfilled, (state, action) => {
      const { organization } = action.payload.attributes

      if (organization) {
        state.activeOrganizationId = organization.id
        state.byId[organization.id] = organization
      }

      if (!state.result.includes(organization?.id)) {
        state.result.push(organization?.id)
      }
    })
    builder.addCase(getOrganizations.pending, (state, action) => {
      state.isLoaded = false
      state.currentPageReference = JSON.stringify(action.meta.arg)
    })

    builder.addCase(getOrganizations.fulfilled, (state, action) => {
      const result: string[] = []
      action.payload.data.forEach((organization, index) => {
        if (index === 0) {
          state.activeOrganizationId = organization.id
        }

        state.byId[organization.id] = {
          id: organization.id,
          type: organization.type,
          ...organization.attributes,
        }
        result.push(organization.id)
      })

      state.isLoaded = true
      state.pagination = action.payload.meta.pagination
      state.result = result

      if (action.meta.arg) {
        state.byPage[JSON.stringify(action.meta.arg)] = result
      }
    })

    builder.addCase(getOrganizations.rejected, (state, action) => {
      state.isLoaded = true
    })
  },
})

export const { setOrganizations } = organizationsSlice.actions

export default organizationsSlice
