// @ts-nocheck
import axios from 'util/axios'
import { AuthorizationResponse } from '../authentication/authentication.service'
import {
  Me,
  Profile,
  ProfileResponse,
  Invitation,
  RecoveryInit,
  RecoveryReset,
  ChangePassword,
  AccountConfirmation,
} from './index'

export async function get() {
  return axios.get<{ data: Me }>('/me').then((response) => response?.data?.data)
}

export async function create(attributes: RecoveryInit): Promise<null> {
  return axios.post('/recoveries', { data: { attributes } }).then((response) => response.data)
}

export async function change(attributes: ChangePassword) {
  return axios
    .post<AuthorizationResponse>('/me/password', { data: { attributes } })
    .then((response) => response?.data)
}

export async function updateProfile(attributes: Partial<Profile>) {
  return axios
    .patch<{ data: ProfileResponse }>('/me/profile', { data: { attributes } })
    .then((response) => response?.data?.data)
}

export async function getProfile() {
  return axios.get<{ data: ProfileResponse }>('/me/profile').then((response) => response?.data.data)
}

export async function update(attributes: RecoveryReset) {
  return axios
    .patch(`/recoveries/${attributes.id}`, { data: { attributes } })
    .then((response) => response.data)
}

export async function confirm(attributes: AccountConfirmation) {
  return axios
    .patch(`/confirmations/${attributes.id}`, { data: { attributes } })
    .then((response) => response.data)
}

export async function accept(attributes: Invitation) {
  return axios.patch(`/assignments/${attributes.id}`).then((response) => response.data)
}
