import { createStyles, makeStyles } from '@mui/styles'
import { lighten } from '@mui/material/styles'

const useStyles = makeStyles((cxTheme) =>
  createStyles({
    grid: {
      display: 'grid',
      'grid-template-columns': 'repeat( auto-fill, minmax(150px, 1fr) )',
    },
    root: {
      border: `3px solid transparent`,
      cursor: 'pointer',
      margin: '20px 0',
      boxShadow: 'none',
      paddingRight: '50px',
      backgroundColor: cxTheme.palette.background.paper,
      color: cxTheme.palette.text.secondary,

      '&:hover': {
        background: lighten(cxTheme.palette.primary.main, 0.95),
      },
    },
    selected: {
      border: `3px solid ${cxTheme.palette.primary.main}`,
      color: cxTheme.palette.secondary.main,
      backgroundColor: `${cxTheme.palette.primary.main} !important`,
    },
    checkbox: {
      opacity: 0,
      height: 0,
      margin: 0,
      position: 'absolute',
    },
    icon: {
      fill: '#95A1AC',
      transition: 'fill 0.3s',
    },
    selectedIcon: {
      marginLeft: '20px',
      color: cxTheme.palette.secondary.main,
    },
    active: {
      fill: '#222222',
    },
    secondary: {
      alignItems: 'center',
      textAlign: 'left',
    },
    '@media (min-width: 768px)': {
      root: {
        paddingRight: '0px',
      },
      selectedIcon: {
        width: '45px',
        maxWidth: '45px',
        position: 'relative',
        right: 'initial',
      },
    },
  })
)

export default useStyles
