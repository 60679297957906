import React, { useEffect } from 'react'
import { Dialog, DialogContent, Typography } from '@mui/material'
import { ReactComponent as KrowLogoIconSvg } from 'assets/icons/krow-logo-loading.svg'
import useStyles from './KrowFullScreenLoading.styles'

interface KrowFullScreenLoadingProps {
  isLoading: boolean
}

function KrowFullScreenLoading(props: KrowFullScreenLoadingProps) {
  const classes = useStyles()

  useEffect(() => {
    if (props.isLoading) {
      document.querySelector('#root')?.classList.add(classes.blur)
    }
    return () => {
      document.querySelector('#root')?.classList.remove(classes.blur)
    }
  }, [props.isLoading, classes.blur])

  return (
    <Dialog open={props.isLoading} fullWidth maxWidth="sm" classes={{ root: classes.root }}>
      <DialogContent sx={{ paddingY: 8 }}>
        <KrowLogoIconSvg className={classes.loading} />
        <Typography variant="h3" className={classes.loadingText}>
          Hold on.. we're looking.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default KrowFullScreenLoading
