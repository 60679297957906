import axios from 'util/axios'
import { v4 as uuid } from 'uuid'
import range from 'lodash/range'
import groupBy from 'lodash/groupBy'
import { organizationId, locationId } from '../path'
import { QuestionnaireResponse } from './index'
import { FieldValues, DomainPatch } from 'routes/Builder/pages/StandardBuilder/StandardBuilder'
import { IncludedQuestionnaire, Patch } from './questionnaires.api'

export async function relationship() {
  return axios
    .get<{ data: QuestionnaireResponse }>(
      `/organizations/${organizationId()}/locations/${locationId()}/relationship/questions`
    )
    .then((response) => {
      const { attributes, ...rest } = response.data.data
      return { ...rest, ...attributes }
    })
}

export async function getOne(id: string) {
  return axios
    .get<{ data: QuestionnaireResponse }>(`/organizations/${organizationId()}/questionnaires/${id}`)
    .then((response) => {
      const { attributes, ...rest } = response.data.data
      return { ...rest, ...attributes }
    })
}

const encodePatch = (
  domainPatch: DomainPatch,
  questionIndex: number
): Patch | Patch[] | undefined => {
  const { action, secondary, tertiary, quaternary } = domainPatch

  if (action === 'jump') {
    const jumpTo = Number(tertiary)
    const groupId = uuid()
    return range(questionIndex + 1, jumpTo).map((pathIndex) => ({
      id: uuid(),
      op: 'replace',
      path: `/questions/${pathIndex}/visible`,
      value: false,
      group_id: groupId,
      from: '',
      group_type: 'jump',
    }))
  }
  if (action === 'remove' && secondary === 'questions') {
    return {
      id: uuid(),
      op: 'replace',
      path: `/questions/${tertiary}/visible`,
      value: false,
      group_id: '',
      group_type: 'remove_question',
      from: '',
    }
  }
  if (action === 'remove' && secondary === 'answers') {
    return {
      id: uuid(),
      op: 'replace',
      path: `/questions/${tertiary}/answers/${quaternary}/visible`,
      value: false,
      group_id: '',
      group_type: 'remove_answer',
      from: '',
    }
  }
  if (action === 'exit' && secondary === 'soft') {
    return {
      id: uuid(),
      op: 'replace',
      path: `/collect/exited`,
      value: true,
      group_id: '',
      group_type: 'soft_hard',
      from: '',
    }
  }

  if (action === 'exit' && secondary === 'hard') {
    return {
      id: uuid(),
      op: 'replace',
      path: `/collect/exited`,
      value: true,
      group_id: '',
      group_type: 'exit_hard',
      from: '',
    }
  }
}

export function decodePatch(
  patch: Patch | Patch[],
  questionIndex: number
): DomainPatch | undefined {
  if (Array.isArray(patch)) {
    return {
      id: patch[0].group_id,
      action: 'jump',
      secondary: 'questions',
      tertiary: questionIndex + patch.length + 1,
    }
  }
  const [, , tertiary, , quaternary] = patch.path.split('/')

  if (patch.group_type === 'remove_question') {
    return {
      id: patch.id,
      secondary: 'questions',
      action: 'remove',
      tertiary,
    }
  }

  if (patch.group_type === 'remove_answer') {
    return {
      id: patch.id,
      secondary: 'answers',
      action: 'remove',
      tertiary,
      quaternary,
    }
  }

  if (patch.group_type === 'exit_hard') {
    return {
      id: patch.id,
      action: 'exit',
      secondary: 'hard',
    }
  }

  if (patch.group_type === 'soft_exit') {
    return {
      id: patch.id,
      action: 'exit',
      secondary: 'soft',
    }
  }
}

export const encodeQuestionnaire = (questionnaire: FieldValues) => {
  return {
    questions: questionnaire.questions.map((question, questionIndex) => {
      return {
        ...question,
        visible: true,
        answers: question.answers?.map((answer) => {
          return {
            ...answer,
            visible: true,
            patches: (answer.patches || [])
              .map((patch) => encodePatch(patch, questionIndex))
              .flat()
              .filter((p) => p) as Patch[],
          }
        }),
      }
    }),
  }
}

export const decodeQuestionnaire = (questionnaire: IncludedQuestionnaire) => {
  return {
    ...questionnaire,
    questions: questionnaire.questions.map((question, questionIndex) => {
      return {
        ...question,
        answers:
          question?.answers?.map((answer) => {
            return {
              ...answer,
              patches: Object.entries(groupBy(answer.patches, (patch) => patch.group_id))
                .reduce<Array<Patch | Patch[]>>((aggregate, [key, group]) => {
                  if (key) return [...aggregate, group]
                  return [...aggregate, ...group]
                }, [])
                .map((patch) => decodePatch(patch, questionIndex))
                .flat()
                .filter((p) => p) as DomainPatch[],
            }
          }) || [],
      }
    }),
  }
}
