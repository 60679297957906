import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store'

const locationIds = (state: RootState) => state.locations.result
const locationsById = (state: RootState) => state.locations.byId
const activeIdSelector = (state: RootState) => state.locations.activeLocationId
const mappedLocations = (
  ids: ReturnType<typeof locationIds>,
  byId: ReturnType<typeof locationsById>
) => {
  return ids.map((id) => byId[id])
}

export const activeLocationSelector = createSelector(
  locationsById,
  activeIdSelector,
  (locations, activeId) => locations[activeId]
)
export const locationsSelector = createSelector(locationIds, locationsById, mappedLocations)
