import React, { useState } from 'react'
import { useDebounce } from 'use-debounce'
import { ControllerRenderProps, ControllerFieldState, UseFormStateReturn } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { OrganizationScope, RegionScope } from 'store/store.types'
import { useListLocationsQuery } from 'store/locations/locations.api'
import { useGetMeQuery } from 'store/me/me.api'

export type FormLocationAutocompleteProperties = {
  field: ControllerRenderProps<any, string>
  fieldState: ControllerFieldState
  formState: UseFormStateReturn<any>
}

export default function FormLocationAutocomplete(props: FormLocationAutocompleteProperties) {
  const { field, fieldState } = props
  const { onChange, ...rest } = field
  const [_search, _setSearch] = useState<string>('')
  const [search] = useDebounce(_search, 400)
  const { organizationId, regionId } = useParams<OrganizationScope & RegionScope>()

  const [_entityType, entityId] = regionId
    ? ['regions', regionId]
    : ['organizations', organizationId]
  const entityType = _entityType as 'regions' | 'organizations'

  const { data: { data: actor } = { data: null }, isLoading: actorLoading } = useGetMeQuery()

  const isOrgMember = actor?.type === 'organization_members'

  const { data = { data: [] }, isLoading } = useListLocationsQuery(
    {
      entityType,
      entityId,
      search,
      filter: { archived: isOrgMember ? false : undefined },
    },
    { skip: actorLoading }
  )

  const options = React.useMemo(() => {
    return data?.data?.map((location) => ({ name: location.name, value: location.id })) || []
  }, [data?.data])

  return (
    <Autocomplete
      {...rest}
      defaultValue=""
      fullWidth
      sx={{ marginBottom: 3 }}
      value={field.value || null}
      options={options}
      getOptionLabel={(option) => option?.name || ''}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      loading={isLoading}
      onChange={(event, option) => {
        onChange(option)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Location"
          variant="outlined"
          required
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              </React.Fragment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          onChange={(event) => _setSearch(event.target.value)}
          error={!!fieldState.error?.message}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}
