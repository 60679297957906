import { combineReducers } from '@reduxjs/toolkit'

import { api } from './store.api'
import me from './me'
import organizations from './organizations'
import snacks from './snacks'
import locations from './locations'
import positions from './positions'
import questions from './questions'
import responses from './responses'
import scope from './scope'
import statistics from './statistics'
import questionnaires from './questionnaires'

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  locations: locations.reducer,
  me: me.reducer,
  organizations: organizations.reducer,
  positions: positions.reducer,
  questions: questions.reducer,
  questionnaires: questionnaires.reducer,
  responses: responses.reducer,
  scope: scope.reducer,
  statistics: statistics.reducer,
  snacks: snacks.reducer,
})

export default rootReducer
