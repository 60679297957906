import React from 'react'
import { timezones } from 'shared/constants/timezones'
import { Autocomplete } from '../Autocomplete'
import { AutocompleteProps } from '../Autocomplete/Autocomplete'

interface TimezonesDropdownProps {
  onChange: AutocompleteProps['onChange']
  value: string
  helperText?: AutocompleteProps['helperText']
  error?: AutocompleteProps['error']
}

function TimezonesDropdown(props: TimezonesDropdownProps) {
  return (
    <Autocomplete
      onChange={props.onChange}
      value={timezones.find((o) => o.value === props.value)}
      label="Timezone"
      data-cy="timezone"
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, selected) => option.value === selected.value}
      options={timezones}
      helperText={props.helperText}
      error={props.error}
    />
  )
}

export default TimezonesDropdown
