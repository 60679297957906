import { alpha } from '@mui/material'

const getHoverColor = (theme) => alpha(theme.palette.grey[100], 0.5)

export const styles = {
  selectAll: (theme) => ({
    color: theme.palette.primary.main,
    cursor: 'pointer',
    paddingLeft: 0.5,
    ':hover': { backgroundColor: getHoverColor(theme) },
  }),
}
