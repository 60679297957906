import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      fill: theme.palette.grey[500],
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    content: {
      paddingBottom: 0,
    },
    tab: {
      minWidth: 100,
      textTransform: 'none',
      '&.Mui-selected': {
        fontWeight: 'bold',
      },
    },
    dropZone: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey['100'],
      border: `2px dashed ${theme.palette.grey['400']}`,
      width: '100%',
    },
    input: {
      '& .MuiFormHelperText-root': {
        color: theme.palette.grey['500'],
      },
    },
    footer: {
      padding: theme.spacing(2.5),
    },
    grid: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1, 2),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
    },
    dialogDescription: {
      marginBottom: theme.spacing(3),
    },
    statusDropdown: {
      marginTop: 20,
      marginBottom: 16,
    },
    selectedStatus: {
      textTransform: 'capitalize',
    },
    statusDescriptionWrapper: {
      marginTop: 30,
    },
  })
)

export { useStyles }
