import { createSlice, createAsyncThunk, SliceCaseReducers } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { Resource, ResponseErrorBody } from '../store.types'
import * as MeService from './me.service'

export interface Assignment {
  name: string
  entity_type: string
  entity_id: string
}

export interface Invitation {
  entity_id: string
  entity_type: string
  id: string
  name: string
}

interface Attributes {
  email_address: string
  organizations: string[]
  created_at: string
  updated_at: string
  assignments: Assignment[]
  invitations: Invitation[]
}

export interface Profile {
  id: string
  type: 'Profile'
  created_at: string
  updated_at: string
  full_name: string
  first_name: string
  last_name: string
  phone_number: string
  relationship: ProfileRelationship
}

interface ProfileRelationship {
  actor: {
    data: {
      id: string
      type: 'active_administrator' | 'organization_member'
    }
  }
}
export type ProfileResponse = {
  id: string
  type: 'Profile'
  attributes: Profile
  relationship: ProfileRelationship
}

export interface ChangePassword {
  current_password: string
  password: string
  password_confirmation: string
}

export interface RecoveryInit {
  email_address: string
}

export interface RecoveryReset {
  id: string
  password: string
  password_confirmation: string
}

export interface AccountConfirmation extends RecoveryReset {}

export interface Me extends Resource<Attributes> {
  type: 'Account' | 'Actor'
  profile?: Profile
}

export type MeState = Me | null

export const getMe = createAsyncThunk('me/get', async (_, ctx) => {
  const me = await MeService.get()
  window.user = { ...me.attributes }
  return me
})

export const createRecovery = createAsyncThunk(
  'recovery/create',
  async (attributes: RecoveryInit, ctx) => {
    return MeService.create(attributes)
  }
)

export const managerRecovery = createAsyncThunk(
  'recovery/manager',
  async (attributes: RecoveryInit, ctx) => {
    return MeService.create(attributes)
  }
)

export const changePassword = createAsyncThunk(
  'change-password',
  async (attributes: ChangePassword, { rejectWithValue }) => {
    return MeService.change(attributes).catch((e: AxiosError<ResponseErrorBody>) =>
      rejectWithValue(e?.response?.data.errors)
    )
  }
)

export const updateRecovery = createAsyncThunk(
  'recovery/create',
  async (attributes: RecoveryReset, ctx) => {
    return MeService.update(attributes)
  }
)

export const updateConfirmation = createAsyncThunk(
  'account/create',
  async (attributes: AccountConfirmation, { rejectWithValue }) => {
    return MeService.confirm(attributes).catch((e: AxiosError<ResponseErrorBody>) =>
      rejectWithValue(e?.response?.data.errors)
    )
  }
)

export const updateInvitation = createAsyncThunk(
  'invitation/accept',
  async (attributes: Invitation, ctx) => {
    return MeService.accept(attributes)
  }
)

export const getProfile = createAsyncThunk('get/me/profile', async (_, ctx) => {
  const profile = await MeService.getProfile()
  window.user = { ...window.user, ...profile.attributes }
  return profile
})

export const updateProfile = createAsyncThunk(
  'patch/me/profile',
  async (update: Partial<Profile>, ctx) => {
    return MeService.updateProfile(update)
  }
)

const meSlice = createSlice<MeState, SliceCaseReducers<MeState>>({
  name: 'me',
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMe.fulfilled, (state, action) => {
      const { id, type, attributes } = action.payload
      const { organizations, assignments, invitations, ...rest } = attributes

      return action.payload
    })

    builder.addCase(getMe.rejected, (state, action) => {
      localStorage.clear()
      state = null
      return state
    })

    builder.addCase(getProfile.fulfilled, (state, action) => {
      if (state) {
        state.profile = action.payload.attributes
      }
      return state
    })

    builder.addCase(updateProfile.fulfilled, (state, action) => {
      if (state) {
        state.profile = action.payload.attributes
      }
      return state
    })
  },
})

export default meSlice
