import React, { useState } from 'react'
import { useDebounce } from 'use-debounce'
import { ControllerRenderProps, ControllerFieldState, UseFormStateReturn } from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { useAllOpeningsQuery } from 'store/openings/opening.api'

export type FormOpeningAutocompleteProperties = {
  field: ControllerRenderProps<any, string>
  fieldState: ControllerFieldState
  formState: UseFormStateReturn<any>
  localityId: string
}

export default function FormOpeningAutocomplete(props: FormOpeningAutocompleteProperties) {
  const { field, fieldState, localityId } = props
  const { onChange, ...rest } = field
  const [_search, _setSearch] = useState<string>('')
  const [search] = useDebounce(_search, 400)
  const { data = { data: [] }, isLoading } = useAllOpeningsQuery({ localityId, search })
  const options = React.useMemo(() => {
    return (
      data?.data?.map((opening) => ({
        name: `${opening.locality.name} - ${opening.position.name}`,
        value: opening.id,
      })) || []
    )
  }, [data?.data])

  return (
    <Autocomplete
      {...rest}
      fullWidth
      sx={{ marginBottom: 3 }}
      options={options}
      getOptionLabel={(option) => option?.name || ''}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      loading={isLoading}
      onChange={(event, option) => {
        onChange(option)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Opening"
          variant="outlined"
          required
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              </React.Fragment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          onChange={(event) => _setSearch(event.target.value)}
          error={!!fieldState.error?.message}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}
