import { createSlice, SliceCaseReducers, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { Questions } from '../questions'
import * as QuestionnaireService from './questionnaire.service'
export interface Field {
  label: string
  name: string
  node: string
  type: 'text' | 'tel' | 'email' | 'date' | 'checkbox'
  visible: boolean
  required: boolean
}
export interface CollectAttribute {
  exited: boolean
  enabled: boolean
  fields: Field[]
}

interface QuestionnairePosition {
  id: string
  name: string
  description: null
  external_id: string
}

type BaseQuestionnaire = {
  id: string
  type: 'Questionnaire'
}

type Attributes = {
  title: string
  description: string
  created_at: string
  name: string
  organization_id: string
  published_at: string
  updated_at: string
  completion_url: string
  questions: Questions[]
  collect: CollectAttribute
  positions: { [key: string]: QuestionnairePosition }
  universal_position: QuestionnairePosition
}

export type Questionnaire = BaseQuestionnaire & Attributes
export type QuestionnaireResponse = BaseQuestionnaire & { attributes: Attributes }
export type BuilderStepOptions = 'builder' | 'logic'

export type QuestionnaireState = {
  byId: { [key: string]: Questionnaire }
  activeQuestionnaireId: string
  activeBuilderStep: BuilderStepOptions
  isValid: boolean
}

export const locationQuestionnaire = createAsyncThunk(
  'questionnaire/relationship',
  async (_, ctx) => {
    return QuestionnaireService.relationship()
  }
)

export const getOneQuestionnaire = createAsyncThunk(
  'questionnaire/getOne',
  async (id: string, ctx) => {
    return QuestionnaireService.getOne(id)
  }
)

const questionsState = createSlice<QuestionnaireState, SliceCaseReducers<QuestionnaireState>>({
  name: 'questions',
  initialState: {
    activeBuilderStep: 'builder',
    byId: {},
    activeQuestionnaireId: '',
    isValid: true,
  },
  reducers: {
    changeBuilderStep(state, action: PayloadAction<BuilderStepOptions>) {
      state.activeBuilderStep = action.payload
    },
    changeQuestionnaireValidity(state, action: PayloadAction<boolean>) {
      state.isValid = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(locationQuestionnaire.fulfilled, (state, action) => {
      state.activeQuestionnaireId = action.payload.id
      state.byId[action.payload.id] = action.payload
    })
    builder.addCase(getOneQuestionnaire.fulfilled, (state, action) => {
      state.activeQuestionnaireId = action.payload.id
      state.byId[action.payload.id] = action.payload
    })
  },
})

export const { changeBuilderStep, changeQuestionnaireValidity } = questionsState.actions

export default questionsState
