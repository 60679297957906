import axios from 'axios'
import qs from 'query-string'
import { encodeNestedParams } from 'store/store.utils'
import runtimeEnv from 'util/runtimeEnv'
import notifyError from '../shared/helpers/notifyError'

const env = runtimeEnv()
const baseURL =
  env.REACT_APP_BASE_API_URL || process.env.REACT_APP_BASE_URL || 'http://localhost:3000'

const instance = axios.create({
  baseURL: `${baseURL}/v1`,
  headers: {
    'content-type': 'application/json',
  },
})

instance.interceptors.request.use((configuration) => {
  const access = JSON.parse(localStorage.getItem('access') as string)
  configuration.headers.Authorization = `Bearer ${access?.attributes?.token}`

  return configuration
})

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (process.env.NODE_ENV === 'production') {
      notifyError(
        new Error(`${err?.response?.config?.method?.toUpperCase()} - ${err?.response.status}`)
      )
    }
    return Promise.reject(err)
  }
)

instance.defaults.paramsSerializer = (params = {}) => {
  const { page, filter, ...rest } = params

  return qs.stringify(
    { ...rest, ...encodeNestedParams('page', page), ...encodeNestedParams('filter', filter) },
    { arrayFormat: 'comma', encode: false }
  )
}

export default instance
