import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import { useGetOneOrganizationQuery } from 'store/organizations/organizations.api'
import { useGetOneLocationQuery } from 'store/locations/locations.api'
import { useGetOneRegionQuery } from 'store/regions/regions.api'
import { skipToken } from '@reduxjs/toolkit/query'
import { useParams } from 'react-router'
import { useStyles } from './SideNavigation.styles'
import { useGetMeQuery } from 'store/me/me.api'

function SideNavigationHeader() {
  const classes = useStyles()
  const { organizationId, regionId: paramRegionId, localityId } = useParams<any>()
  const { data: organization } = useGetOneOrganizationQuery(
    organizationId ? organizationId : skipToken
  )
  const { data: locality } = useGetOneLocationQuery(localityId ? localityId : skipToken)
  const { data: region } = useGetOneRegionQuery(paramRegionId ? paramRegionId : skipToken)

  const regionName = region?.data.name || locality?.data.region.name
  const regionId = region?.data.id || locality?.data.region.id

  const { data: me } = useGetMeQuery()
  const access = me?.data.type
  const restriction = me?.data.entity_type

  const adminAccess = ['active_administrators', 'account_managers'].some((type) => type === access)
  const organizationAccess =
    adminAccess || (access === 'organization_members' && restriction === 'Organization')
  const regionAccess =
    adminAccess ||
    organizationAccess ||
    (access === 'organization_members' && restriction === 'Region')
  const locationAccess =
    adminAccess ||
    organizationAccess ||
    regionAccess ||
    (access === 'organization_members' && restriction === 'Location')

  const crumbs = [
    { condition: adminAccess, name: 'Admin', href: `/organizations` },
    {
      condition: organizationId && organizationAccess,
      name: organization?.data?.name,
      href: `/organizations/${organizationId}/dashboard`,
    },
    {
      condition: regionId && regionAccess,
      name: regionName,
      href: `/organizations/${organizationId}/regions/${regionId}/dashboard`,
    },
    {
      condition: localityId && locationAccess,
      name: locality?.data?.name,
      href: `/organizations/${organizationId}/localities/${localityId}/dashboard`,
    },
  ]

  return (
    <Box className={classes.sideNavHeader}>
      <Breadcrumbs
        maxItems={2}
        aria-label="breadcrumb"
        itemsBeforeCollapse={0}
        itemsAfterCollapse={2}
        sx={{ fontSize: 12 }}
        className={classes.sideNavHeaderBreadcrumbs}
        classes={{ separator: classes.separator }}
      >
        {crumbs.map(({ name, href, condition }, index) =>
          condition ? (
            <Link key={index} color="secondary" underline="hover" href={href}>
              {name}
            </Link>
          ) : null
        )}
      </Breadcrumbs>
      <Box className={classes.sideNavHeaderOrganizationNameWrapper}>
        <Typography
          color="common.white"
          variant="h4"
          sx={{ whiteSpace: 'normal' }}
          className={classes.sideNavHeaderOrganizationName}
        >
          {locality?.data?.name || region?.data?.name || organization?.data?.name}
        </Typography>
        <Typography color="secondary" variant="body2">
          {locality?.data?.name
            ? 'Location'
            : region?.data?.name
            ? 'Region'
            : organization?.data?.name
            ? 'Organization'
            : ''}
        </Typography>
      </Box>
      <Box className={classes.sideNavHeaderSeparator}></Box>
    </Box>
  )
}

export default SideNavigationHeader
