import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    [theme.breakpoints.up('lg')]: {
      position: 'fixed',
      width: 80,
      height: '100%',
      zIndex: 5,
      transitionDuration: '946ms',
      transitionProperty: 'box-shadow, background-color',
      transitionTimeFunction: 'cubic-bezier(0.2,0,0.2,0)',
    },
  },
  drawer: {
    background: theme.palette.grey['900'],
    boxShadow: '0 2px 7px 0 rgba(36,36,36,0.2)',
    flexShrink: 0,
    overflow: 'visible',
    whiteSpace: 'nowrap',
    width: 300,
    top: 56,
    [theme.breakpoints.up('sm')]: {
      top: 80,
    },
    [theme.breakpoints.up('lg')]: {
      transition: 'width 346ms ease',
      width: 80,
    },
    [`& .MuiDrawer-paper`]: {
      width: 80,
    },
  },
  expandedSidebar: {
    width: 300,
    boxShadow: '0 2px 7px 0 rgb(36 36 36 / 20%)',
    [`& .MuiDrawer-paper`]: {
      width: 300,
    },
    '& $linkText': {
      [theme.breakpoints.up('lg')]: {
        opacity: 1,
      },
    },
    '& $sideNavHeaderBreadcrumbs': {
      color: theme.palette.secondary.main,
      [theme.breakpoints.up('lg')]: {
        maxHeight: 120,
        visibility: 'visible',
        opacity: 1,
      },
    },
    '& $sideNavHeaderOrganizationNameWrapper': {
      [theme.breakpoints.up('lg')]: {
        visibility: 'visible',
        maxHeight: 120,
        opacity: 1,
      },
    },
  },
  slider: {
    minHeight: '100%',
  },
  sideNavHeader: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 16,
    paddingLeft: 32,
    paddingBottom: 24,
    paddingRight: 32,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 5,
      paddingRight: 5,
      paddingBottom: 0,
    },
  },
  sideNavHeaderBreadcrumbsPlaceHolder: {
    display: 'flex',
    backgroundColor: '#F1F4F8',
    color: '#384550',
    width: 24,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 15,
    borderRadius: 2,
    fontSize: 2,
    '& > svg': {
      width: 24,
      height: 16,
    },
  },
  sideNavHeaderBreadcrumbs: {
    paddingBottom: 10,
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('lg')]: {
      opacity: 0,
      visibility: 'hidden',
      overflow: 'hidden',
      maxHeight: 0,
      transition: 'max-height 0.45s ease-in-out, visibility 0.4s linear, opacity 0.1s linear',
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  organizationLogoWrapper: {
    display: 'flex',
    maxWidth: 69,
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  organizationLogoImage: {
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
  },
  iconWrapper: {
    paddingLeft: 38,
    paddingRight: 38,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    '& > div': {
      [theme.breakpoints.up('lg')]: {
        justifyContent: 'center',
      },
    },
  },
  icon: {
    fill: theme.palette.secondary.main,
  },
  sideNavHeaderOrganizationNameWrapper: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('lg')]: {
      whiteSpace: 'nowrap',
      visibility: 'hidden',
      overflow: 'hidden',
      maxHeight: 0,
      transition: 'max-height 0.45s ease-in-out, visibility 0.4s linear, opacity 0.1s linear',
      paddingLeft: 12,
      paddingRight: 12,
      opacity: 0,
    },
  },
  sideNavHeaderOrganizationName: {
    color: 'common.white',
    [theme.breakpoints.up('lg')]: {
      overFlow: 'hidden',
    },
  },
  sideNavHeaderSeparator: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 24,
    borderBottom: `1px solid ${theme.palette.grey['700']}`,
  },
  link: {
    textDecoration: 'none',
    borderBottom: `1px solid #262d34`,
    display: 'block',
    position: 'relative',
    '&::before': {
      backgroundColor: theme.palette.secondary.main,
      bottom: 0,
      content: '""',
      left: '-2px',
      position: 'absolute',
      top: 0,
      width: '2px',
      zIndex: theme.zIndex.drawer,
    },
  },
  activeLink: {
    '&::before': {
      left: 0,
    },
    '& > div': {
      background: theme.palette.grey['A100'],
    },
    '& *': {
      color: theme.palette.common.white,
    },
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
  linkText: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('lg')]: {
      opacity: 0,
    },
  },
  separator: {
    margin: `${0} 4px`,
  },
}))

export { useStyles }
