import { forwardRef } from 'react'
import { Box, Typography, Checkbox } from '@mui/material'
import { size, eq } from 'lodash'
import { styles } from './styles'

const AutoCompleteListbox = forwardRef(function AutocompleteListBox(props, ref) {
  const { children, selected = [], setSelected, options = [], ...rest } = props

  const allSelected = eq(size(options), size(selected))

  return (
    <ul {...rest} ref={ref}>
      <Box
        component="li"
        onClick={() => setSelected(allSelected && size(options) > 1 ? [] : options)}
        sx={styles.selectAll}
      >
        <Box display="flex" alignItems="center">
          <Checkbox checked={allSelected} />
          <Typography variant="body2">Select All</Typography>
        </Box>
      </Box>
      {children.map((child) => (
        <Box component="li" key={child.key}>
          {child}
        </Box>
      ))}
    </ul>
  )
})

export default AutoCompleteListbox
