import capitalize from 'lodash/capitalize'
import { createSlice, createAsyncThunk, SliceCaseReducers } from '@reduxjs/toolkit'
import * as StatisticsService from './statistics.service'

export type TimeseriesNames = 'campaigns_over_time' | 'mediums_over_time' | 'sources_over_time'
export type CountNames =
  | 'average_days_from_response_to_decision'
  | 'number_of_scheduled_interviews'
  | 'number_of_responses'
export type StatisticNames = TimeseriesNames | CountNames

export const statNameCase = (name: StatisticNames | string) => {
  switch (name) {
    case 'average_days_from_response_to_decision':
      return 'Average Days from Apply to Decision'
    case 'number_of_scheduled_interviews':
      return 'Number of Scheduled Interviews'
    case 'number_of_responses':
      return 'Total Number of Candidates'
    case 'campaigns_over_time':
      return 'Campaigns over Time'
    case 'mediums_over_time':
      return 'Mediums over Time'
    case 'sources_over_time':
      return 'Sources over time'
    default:
      return name.split('_').map(capitalize).join(' ')
  }
}

type StatisticTypes = 'Timeseries' | 'Count'

interface Attributes<T, N> {
  actor_id: string
  cached_at: null | string
  created_at: string
  duration: number
  frequency: string
  location_id: null | string
  name: N
  organization_id: string
  updated_at: string
  result: T
}

type TimeSeriesNode = {
  label: string
  total: number
  week: string
}
type TimeseriesResult = TimeSeriesNode[]
export type TimeseriesStatistic = {
  id: string
  type: 'Timeseries'
  attributes: Attributes<TimeseriesResult, TimeseriesNames>
}

type CountResult = { count: number }
export type CountStatistic = {
  id: string
  type: 'Count'
  attributes: Attributes<CountResult, CountNames>
}

export type Statistics = CountStatistic | TimeseriesStatistic

export function isTimeseriesStatistic(statistic: Statistics): statistic is TimeseriesStatistic {
  return (statistic as TimeseriesStatistic)?.type === 'Timeseries'
}

export function isCountStatistic(statistic?: Statistics): statistic is CountStatistic {
  return (statistic as CountStatistic)?.type === 'Count'
}

type StatisticNameMap<T> = { [key in StatisticNames | string]: T }
export type List = { name: StatisticNames; type: StatisticTypes }[]
export type StatisticsState = {
  byId: StatisticNameMap<Statistics>
  result: string[]
  list: List
  loading: StatisticNameMap<boolean>
  isLoaded: boolean
}

export const getStatistics = createAsyncThunk('statistics/get', async (params, ctx) => {
  return StatisticsService.get()
})

export const getOneStatistic = createAsyncThunk<Statistics, StatisticNames>(
  'statistics/getOne',
  async (name: StatisticNames, ctx) => {
    return StatisticsService.getOne(name)
  }
)

const StatisticsStateSlice = createSlice<StatisticsState, SliceCaseReducers<StatisticsState>>({
  name: 'statistics',
  initialState: {
    byId: {},
    result: [],
    list: [],
    isLoaded: false,
    loading: {} as StatisticNameMap<boolean>,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatistics.fulfilled, (state, action) => {
      state.isLoaded = true
      state.list = action.payload
    })
    builder.addCase(getOneStatistic.pending, (state, action) => {
      state.loading[action.meta.arg] = true
    })
    builder.addCase(getOneStatistic.fulfilled, (state, action) => {
      state.loading[action.meta.arg] = false
      state.byId[action.meta.arg] = action.payload
      state.result = Array.from(new Set([...state.result, action.meta.arg]))
    })
    builder.addCase(getOneStatistic.rejected, (state, action) => {
      state.loading[action.meta.arg] = false
    })
  },
})

export const { setActivePosition } = StatisticsStateSlice.actions

export default StatisticsStateSlice
