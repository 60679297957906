import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

export const FullScreenLoading = () => (
  <Box
    data-cy="main-loader"
    display="flex"
    minHeight="100vh"
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress />
  </Box>
)

export default FullScreenLoading
