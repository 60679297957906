import React, { useState, useEffect } from 'react'
import capitalize from 'lodash/capitalize'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import { ConfirmationUpdateDialog } from '../../../routes/CandidateProfile/components/ConfirmationUpdateDialog'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'
import { useAppDispatch } from 'shared/hooks/redux'
import { addSnack } from 'store/snacks'
import { TStatefulResource, useUpdateStateMutation } from 'store/states/states.api'
import { useOrgSettings } from '../../hooks/useOrgSettings'

export type KrowStateTransitionerEntity = {
  id: string
  full_name: string
  type: TStatefulResource
  state: { name: string; action: string }
  transitions: { name: string; action: string }[]
  transitioning: boolean
  opening: object
  locality: object
}

export type ModificationTypes = 'update'

export type KrowStateTransitionerProperties = {
  link: () => Promise<KrowStateTransitionerEntity>
  requireConfirmation?: boolean
  onComplete: (result: KrowStateTransitionerEntity) => void
} & KrowStateTransitionerEntity

export default function KrowStateTransitioner(props: KrowStateTransitionerProperties) {
  const {
    type,
    id,
    state,
    full_name,
    transitions,
    link,
    onComplete,
    requireConfirmation,
    opening,
    locality,
  } = props

  const dispatch = useAppDispatch()
  const { aliases } = useOrgSettings()

  const [modification, modify] = React.useState<{
    option: {
      name: string
      action: string
    }
    type: ModificationTypes
  } | null>()

  const [currentState, setCurrentState] = useState<{ name: string; action: string }>(state)
  const [loading, setLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<{ name: string; action: string }[]>(
    [currentState].concat(transitions || [])
  )
  const [message, setMessage] = useState<string>('loading')

  useEffect(() => {
    setCurrentState(state)
  }, [state])

  const request = (
    link: () => Promise<KrowStateTransitionerEntity>
  ): Promise<KrowStateTransitionerEntity> => link()
  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
  const poll = async (
    link: () => Promise<KrowStateTransitionerEntity>,
    interval: number,
    attempts: number
  ): Promise<KrowStateTransitionerEntity> => {
    return new Promise(async (resolve, reject) => {
      setLoading(true)

      for (let i = 0; i < attempts; i++) {
        const result = await request(link)

        if (result.transitioning) {
          await sleep(interval)
        } else if (i + 1 === attempts) {
          setLoading(false)
          reject('Failed to refresh state')
        } else {
          setLoading(false)
          resolve(result)
          break
        }
      }
    })
  }

  const [update] = useUpdateStateMutation()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (loading) return

    setOptions(transitions?.concat([state]))
  }, [loading, transitions, state])
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleUpdateStatus = async (option: any) => {
    modify(null)
    setMessage('updating')
    setLoading(true)
    update({ type, id, stateId: option.name, alias: option.alias })
      .unwrap()
      .then(async () => {
        const result = await poll(link, 1 * 1000, 20)
        setOptions([result.state].concat(result.transitions || []))
        setCurrentState(option)
        dispatch(
          addSnack({ message: `Successfully updated the ${type} status.`, severity: 'success' })
        )
        onComplete(result)
      })
      .catch(() => {
        dispatch(addSnack({ message: `Failed to update the ${type} status.`, severity: 'error' }))
      })
  }

  const handleTransition = (event: SelectChangeEvent<string>) => {
    const option = options.find((state) => state.name === event.target.value)

    if (!option) return

    if (!requireConfirmation) {
      handleUpdateStatus(option)
    } else {
      modify({ option: option, type: 'update' })
    }
  }

  const handleAliasChange = (option: { name: string; action: string }) => {
    if (currentState.name !== option.name) {
      return
    }

    if (option.name === 'hired' || option.name === 'rejected') {
      return
    }
    if (aliases?.[option.name]?.length) {
      if (!requireConfirmation) {
        handleUpdateStatus(option)
      } else {
        modify({ option: option, type: 'update' })
      }
    }
  }

  return (
    <>
      <Grid container>
        {loading ? (
          <Grid
            container
            width="100%"
            alignItems="center"
            justifyContent="center"
            direction="row"
            wrap="nowrap"
          >
            <Grid>{capitalize(message)}</Grid>
            <Grid container width="100%" alignItems="center" justifyContent="center">
              <CircularProgress size={20} />
            </Grid>
          </Grid>
        ) : (
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              disableUnderline
              color="primary"
              value={currentState.name}
              onChange={handleTransition}
            >
              {options?.map((option, index) => (
                <MenuItem key={index} value={option.name} onClick={() => handleAliasChange(option)}>
                  <Typography variant="body2">
                    {(currentState.name === option.name ? option.name : option.action)
                      .split('_')
                      .map((segment) => capitalize(segment))
                      .join(' ')}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
      {modification && (
        <ConfirmationUpdateDialog
          title="Update Candidate Status"
          description="Update the status of this Candidate below."
          open={modification?.type === 'update'}
          onClose={() => modify(null)}
          applicant={{ id, full_name, opening }}
          localityId={locality.id}
          selectedStatus={modification.option}
          onUpdate={handleUpdateStatus}
        />
      )}
    </>
  )
}
