import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Fab from '@mui/material/Fab'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Badge from '@mui/material/Badge'
import StaticDatePicker from '@mui/lab/StaticDatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { Scope } from 'store/store.types'
import { useGetOneCalendarQuery } from 'store/calendars/calendars'
import cx from 'clsx'
import { useStyles } from './KrowStaticDatePicker.styles'

interface KrowStaticDatePickerProps {
  date: dayjs.Dayjs | null
  handleSetDate: (date: Dayjs | null) => void
  availableDays: string[]
}

function KrowStaticDatePicker(props: KrowStaticDatePickerProps) {
  const { date, handleSetDate, availableDays } = props
  const classes = useStyles()
  const [, setMonth] = useState<dayjs.Dayjs>(dayjs())
  const { localityId, regionId } = useParams<Scope>()
  const { data: calendar } = useGetOneCalendarQuery({
    localityId: regionId ? regionId : localityId,
    localityType: regionId ? 'regions' : 'locations',
  })

  const timezone = calendar?.data.parsed_ical.timezone
  const events = calendar?.data.parsed_ical.events

  const setOfInterviews = useMemo(
    () =>
      new Set(
        events
          ?.filter((event) =>
            event.component.jCal[1]?.some(
              ([name, object, type, value]) =>
                name.toLowerCase() === 'x-krow-reservation-type' &&
                value.toLowerCase() === 'interview'
            )
          )
          .map((event) => {
            const start = event.component.jCal[1]?.find(
              ([name, ..._]) => name.toLowerCase() === 'dtstart'
            )[3]

            return dayjs.tz(start, timezone).dayOfYear()
          })
      ),
    [events, timezone]
  )

  const getDayElement = (day, props) => {
    let dateTile
    const isSelected = dayjs(date).isSame(dayjs(day), 'day')
    const isToday = dayjs().isSame(dayjs(day), 'day')
    if (!props.outsideCurrentMonth) {
      if (setOfInterviews.has(day.dayOfYear()) && day.dayOfYear() !== date?.dayOfYear()) {
        dateTile = (
          <Paper
            onClick={() => handleSetDate(day)}
            className={
              isSelected
                ? classes.selectedDayPaper
                : isToday
                ? classes.todayPaper
                : classes.normalDayPaper
            }
          >
            <Badge
              key={day.dayOfYear()}
              classes={{ dot: classes.dot }}
              color="primary"
              variant="dot"
            >
              <Grid item> {dayjs(day).date()}</Grid>
            </Badge>
          </Paper>
        )
      } else if (
        availableDays.some(
          (check) => check.toLowerCase() === dayjs.weekdays()[day.day()].toLowerCase()
        )
      ) {
        dateTile = (
          <Paper
            onClick={() => handleSetDate(day)}
            className={
              isSelected
                ? classes.selectedDayPaper
                : isToday
                ? classes.todayPaper
                : classes.normalDayPaper
            }
          >
            <Badge
              key={day.dayOfYear()}
              classes={{ dot: classes.dot }}
              color="secondary"
              variant="dot"
            >
              <Grid item> {dayjs(day).date()}</Grid>
            </Badge>
          </Paper>
        )
      } else {
        dateTile = (
          <Paper className={isToday ? classes.todayDisabledPaper : classes.disabledDayPaper}>
            <Grid item>{dayjs(day).date()}</Grid>
          </Paper>
        )
      }
    } else {
      dateTile = (
        <Paper className={classes.notInThisMonthDayPaper}>
          <Grid item style={{ color: 'lightGrey' }}>
            {dayjs(day).date()}
          </Grid>
        </Paper>
      )
    }
    return dateTile
  }

  return (
    <Box position="sticky" top="120px" className={classes.root}>
      <StaticDatePicker
        openTo="day"
        className={cx(classes.sectionBorderRight, date && 'calendarWithValue')}
        displayStaticWrapperAs="desktop"
        value={() => date}
        shouldDisableYear={() => true}
        disableOpenPicker={true}
        views={['day']}
        onChange={(newValue) => {
          handleSetDate(newValue)
        }}
        onMonthChange={(day) => {
          setMonth(day.endOf('day'))
        }}
        renderInput={() => null}
        renderDay={(day, _, props) => getDayElement(day, props)}
        components={{
          LeftArrowButton: (props) => (
            <Fab
              size="small"
              aria-label="prev-month"
              {...props}
              className={classes.calendarArrowButton}
            >
              <ArrowBackIosNewIcon />
            </Fab>
          ),
          RightArrowButton: (props) => (
            <Fab
              size="small"
              aria-label="next-month"
              {...props}
              className={classes.calendarArrowButton}
            >
              <ArrowForwardIosIcon />
            </Fab>
          ),
        }}
      />
      <Grid container className={classes.calendarBottomLabels}>
        <Grid item xs={6} className={classes.calendarBottomLabelItem}>
          <Badge classes={{ dot: classes.labelDot }} color="secondary" variant="dot"></Badge>
          <Box className={classes.calendarBottomLabelText}>Available</Box>
        </Grid>
        <Grid item xs={6} className={classes.calendarBottomLabelItem}>
          <Badge classes={{ dot: classes.labelDot }} color="primary" variant="dot"></Badge>
          <Box className={classes.calendarBottomLabelText}>Scheduled</Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default KrowStaticDatePicker
