import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '225px',
  },
  popper: {
    zIndex: theme.zIndex.snackbar,
  },
}))

export { useStyles }
