import { api } from '../store.api'
import { providesList } from 'store/store.utils'
import deserialize from 'util/deserialize'

export const Status = {
  created: 'created',
  pending: 'pending',
  rejected: 'rejected',
}

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    allOrganizations: build.query({
      query(data) {
        const { filter, sort, page, search } = data || {}
        return {
          method: 'GET',
          url: `me/organizations`,
          params: {
            page,
            filter,
            sort,
            search,
          },
        }
      },
      transformResponse: (response) => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result) => providesList(result?.data, 'Organization'),
    }),
    getOneOrganization: build.query({
      query(id) {
        return {
          method: 'GET',
          url: `organizations/${id}`,
          params: {
            included: 'configuration',
          },
        }
      },
      transformResponse: (response) => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result, error, arg) => [{ type: 'Organization', id: arg }],
    }),
    updateOrganization: build.mutation({
      query(data) {
        const { id, ...attributes } = data
        return {
          method: 'PATCH',
          url: `organizations/${id}`,
          body: {
            data: { attributes },
          },
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Organization', id }],
    }),
    createOrganization: build.mutation({
      query(attributes) {
        return {
          method: 'POST',
          url: `organizations`,
          body: {
            data: { attributes },
          },
        }
      },
      invalidatesTags: (result) => [{ type: 'Organization', id: 'LIST' }],
    }),
    deleteOrganization: build.mutation({
      query(id) {
        return {
          method: 'DELETE',
          url: `organizations/${id}`,
        }
      },
    }),
    createScopedPresignedUrl: build.mutation({
      query(data) {
        const { organizationId, attributes } = data
        return {
          method: 'POST',
          url: `organizations/${organizationId}/presigned_url`,
          body: {
            data: {
              attributes,
            },
          },
        }
      },
      transformResponse: (response) => response.data,
    }),
    createPresignedUrl: build.mutation({
      query(attributes) {
        return {
          method: 'POST',
          url: `presigned_url`,
          body: {
            data: {
              attributes,
            },
          },
        }
      },
      transformResponse: (response) => response.data,
    }),
    createLogo: build.mutation({
      query(data) {
        const { organizationId, id } = data
        return {
          method: 'POST',
          url: `organizations/${organizationId}/logo`,
          body: {
            data: { attributes: { logo: id } },
          },
        }
      },
      invalidatesTags: (result, error, { organizationId }) => [
        { type: 'Organization', id: organizationId },
      ],
    }),
    deleteLogo: build.mutation({
      query(id) {
        return {
          method: 'DELETE',
          url: `organizations/${id}/logo`,
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'Organization', id }],
    }),
  }),

  overrideExisting: false,
})

export const {
  useAllOrganizationsQuery,
  useGetOneOrganizationQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useCreateLogoMutation,
  useDeleteLogoMutation,
  useCreatePresignedUrlMutation,
  useCreateScopedPresignedUrlMutation,
  useLazyAllOrganizationsQuery,
} = extendedApi
