import React, { useState } from 'react'
import { useDebounce } from 'use-debounce'
import { ControllerRenderProps, ControllerFieldState, UseFormStateReturn } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { OrganizationScope } from 'store/store.types'
import { useAllPositionsQuery } from 'store/positions/position.api'

export type FormPositionAutocompleteProperties = {
  field: ControllerRenderProps<any, string>
  fieldState: ControllerFieldState
  formState: UseFormStateReturn<any>
}

export default function FormPositionAutocomplete(props: FormPositionAutocompleteProperties) {
  const { field, fieldState } = props
  const { onChange, ...rest } = field
  const [_search, _setSearch] = useState<string>('')
  const [search] = useDebounce(_search, 400)
  const { organizationId } = useParams<OrganizationScope>()
  const { data = { data: [] }, isLoading } = useAllPositionsQuery({ organizationId, search })
  const options = React.useMemo(() => {
    return data?.data?.map((position) => ({ name: position.name, value: position.id })) || []
  }, [data?.data])

  return (
    <Autocomplete
      {...rest}
      fullWidth
      sx={{ marginBottom: 3 }}
      options={options}
      getOptionLabel={(option) => option?.name || ''}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      loading={isLoading}
      onChange={(event, option) => {
        onChange(option)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Position"
          variant="outlined"
          required
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              </React.Fragment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          onChange={(event) => _setSearch(event.target.value)}
          error={!!fieldState.error?.message}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}
