import Link from '@mui/material/Link'
import { PhoneNumberText } from 'shared/components/NumberFormats'

interface PhoneLinkProps {
  component: any
  phoneNumber: string
  color: string
}

function PhoneLink(props: PhoneLinkProps) {
  const { component, phoneNumber, color, ...other } = props

  return (
    <Link
      {...other}
      style={{ color: color }}
      underline="hover"
      component={component}
      href="#"
      onClick={(e) => {
        window.location.href = `tel:${phoneNumber}`
        e.preventDefault()
      }}
    >
      <PhoneNumberText phoneNumber={phoneNumber} />
    </Link>
  )
}

export default PhoneLink
