import { api } from '../store.api'
import { providesList } from 'store/store.utils'
import { KrowCollection } from '../industry'
import { EntityScope, CountryScope, ListQueryParams } from 'store/store.types'

export interface Country {
  name: string
  iso: string
  area: string
  created_at: string
  updated_at: string
}

export interface Area {
  name: string
  iso: string
  created_at: string
  updated_at: string
}

export type CountryCollectionResult = KrowCollection<'country', Country>
export type AreaCollectionResult = KrowCollection<'area', Area>

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    listCountries: build.query<CountryCollectionResult, ListQueryParams>({
      query({ search, sort, page = { number: 1, size: 25 } }) {
        return {
          method: 'GET',
          url: `countries`,
          params: {
            search,
            sort,
            page,
          },
        }
      },
      providesTags: (result) => (result?.data ? providesList(result?.data, 'Country') : []),
    }),
    allAreas: build.query<AreaCollectionResult, ListQueryParams & EntityScope>({
      query({ entityType, entityId, search, sort = 'name', page = { number: 1, size: 100 } }) {
        return {
          method: 'GET',
          url: `${entityType}/${entityId}/areas`,
          params: {
            search,
            sort,
            page,
          },
        }
      },
      providesTags: (result) => (result?.data ? providesList(result?.data, 'Area') : []),
    }),
    listAreas: build.query<AreaCollectionResult, ListQueryParams & CountryScope>({
      query({ countryId, search, sort = 'name', page = { number: 1, size: 100 } }) {
        return {
          method: 'GET',
          url: `countries/${countryId}/areas`,
          params: {
            search,
            sort,
            page,
          },
        }
      },
      providesTags: (result) => (result?.data ? providesList(result?.data, 'Area') : []),
    }),
  }),

  overrideExisting: false,
})

export const { useListCountriesQuery, useListAreasQuery, useAllAreasQuery } = extendedApi
