import React, { useState, createContext } from 'react'
import { useQueryParams, StringParam, withDefault } from 'use-query-params'
import { useParams } from 'react-router-dom'
import { useOpeningApplicationQuery } from 'store/openings/opening.api'
import * as jsonpatch from 'fast-json-patch'
import { useAppDispatch } from 'shared/hooks/redux'
import {
  Question,
  useGetOneUndecodedQuestionnaireQuery,
} from 'store/questionnaires/questionnaires.api'
import { useApplicationRouting } from 'shared/hooks/useApplicationRouting'
import { removeAnswer } from 'store/responses'
import { HandledQueryError } from 'store/store.utils'
import { SerializedError } from '@reduxjs/toolkit'

const initialState = {
  question: undefined as unknown as Question,
  next: (patches: jsonpatch.Operation[] = []) => {},
  back: () => {},
  title: '',
  description: '',
  isFirst: true,
  retainmentToken: '',
  completionUrl: '',
  agreements: [],
  isLoading: true,
  error: undefined as unknown as HandledQueryError | SerializedError | undefined,
}
type UseApplication = typeof initialState
type QuestionnaireStack = { questions: Question[]; pointer: number }

const ApplicationPatchContext = createContext<UseApplication>(initialState)

interface ApplicationPatchProviderProps {
  children: React.ReactNode
}

export function ApplicationPatchProvider(props: ApplicationPatchProviderProps) {
  const dispatch = useAppDispatch()
  const [, setStep] = useApplicationRouting()
  const [questionnaires, setQuestionnaire] = useState<QuestionnaireStack[]>([])

  const [marketing] = useQueryParams({
    utm_source: withDefault(StringParam, ''),
    utm_medium: withDefault(StringParam, ''),
    utm_campaign: withDefault(StringParam, ''),
    utm_term: withDefault(StringParam, ''),
    utm_content: withDefault(StringParam, ''),
  })

  const { openingId, questionnaireId } = useParams<{ openingId: string; questionnaireId: string }>()
  const preview = 'string' === typeof questionnaireId

  const {
    data: nonPreviewData,
    error: nonPreviewError,
    isLoading: nonPreviewLoading,
  } = useOpeningApplicationQuery({ openingId, ...marketing }, { skip: preview })
  const {
    data: previewData,
    error: previewError,
    isLoading: previewLoading,
  } = useGetOneUndecodedQuestionnaireQuery(questionnaireId, { skip: !preview })

  const isLoading = nonPreviewLoading || previewLoading
  // eslint-disable-next-line
  const data = isLoading
    ? null
    : nonPreviewData || { data: { attributes: { ...(previewData?.data || {}) } } }
  const error = nonPreviewError || previewError

  /**
   * @TODO: Grab opening as well, providing opening position, location, organization
   * and organization configuration to context.
   */
  React.useEffect(() => {
    if (questionnaires.length === 0 && data) {
      setQuestionnaire([{ questions: data.data.attributes.questions, pointer: 0 }])
    }
  }, [data, questionnaires])

  async function next(patches: jsonpatch.Operation[] = []) {
    const next = jsonpatch.applyPatch<QuestionnaireStack>(
      questionnaire,
      patches,
      false,
      false
    ).newDocument
    const { pointer: currentIndex } = next
    const nextQuestionIndex =
      next.questions.slice(next.pointer + 1).findIndex((question) => question.visible) + 1

    if (nextQuestionIndex !== 0) {
      setQuestionnaire([{ ...next, pointer: currentIndex + nextQuestionIndex }, ...questionnaires])
    } else {
      setStep('information')
    }
  }

  function back() {
    questionnaires.shift()
    const [questionnaire] = questionnaires
    const question = questionnaire.questions[questionnaire.pointer]
    dispatch(removeAnswer(question.id))
    setQuestionnaire([...questionnaires])
  }

  const [questionnaire] = questionnaires
  const question = questionnaire?.questions?.[questionnaire.pointer]

  return (
    <ApplicationPatchContext.Provider
      value={{
        question,
        next,
        back,
        isFirst: questionnaires.length === 1,
        retainmentToken: data?.data.attributes.token || '',
        completionUrl: data?.data.attributes.completion_url,
        agreements: data?.data.attributes.agreements || [],
        title: data?.data.attributes.title,
        description: data?.data.attributes.description,
        error,
        isLoading,
      }}
    >
      {props.children}
    </ApplicationPatchContext.Provider>
  )
}

export function useApplicationPatch() {
  return React.useContext(ApplicationPatchContext)
}
