import { Component, ErrorInfo } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { Notifier } from '@airbrake/browser'
import notifyError from '../../helpers/notifyError'

interface ErrorBoundaryState {
  error?: Error
  hasError: boolean
}

export default class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  readonly airbrake: Notifier | null = null

  constructor(props = {}) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
    notifyError(error)
  }

  get headerHeight() {
    return document.querySelector('#app-header')?.getBoundingClientRect().height || 0
  }

  render() {
    const { hasError, error } = this.state

    if (hasError) {
      notifyError(new Error('[DEBUG] ErrorBoundary fallback'))
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight={`calc(100vh - ${this.headerHeight}px - 48px)`}
        >
          <Box
            component="section"
            bgcolor="background.paper"
            borderRadius="1px"
            maxWidth={540}
            width="calc(100% - 20px)"
          >
            <Box p={4.5}>
              {error?.name === 'ChunkLoadError' ||
              error?.message?.includes("Unexpected token '<'") ? (
                <Typography variant="body1" style={{ verticalAlign: 'baseline' }}>
                  This application has been updated, please{' '}
                  <Link
                    style={{ verticalAlign: 'baseline' }}
                    component="button"
                    variant="body1"
                    onClick={() => window.location.reload()}
                  >
                    refresh
                  </Link>{' '}
                  your browser to see the latest content.
                </Typography>
              ) : (
                <Typography variant="body1">
                  An error has occurred, please refresh the page and try again.
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      )
    }

    return this.props.children
  }
}
