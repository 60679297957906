import { EnhancedStore } from '@reduxjs/toolkit'
import { RootState } from 'store'

/*
 * You might be wondering while this file exists. It was to resolve a module resolution
 * issue that appeared in Jest but not locally development. It may have fixed itself
 * by this point.
 */

class StoreRegister {
  myStore: EnhancedStore<RootState> | null

  constructor() {
    this.myStore = null
  }

  set store(store: EnhancedStore<RootState>) {
    this.myStore = store
  }

  get store(): EnhancedStore<RootState> {
    return this.myStore as EnhancedStore<RootState>
  }
}

export default new StoreRegister()
