import { api } from '../store.api'
import { providesList } from 'store/store.utils'
import deserialize from 'util/deserialize'
import { KrowIdentity, KrowDocument, KrowResponse } from '../industry'
import { vCalendarParser } from 'util/vCalendarParser'
import { Position } from 'store/positions'
import { Opening } from 'store/openings'

export interface CalendarAttributes {
  ical: string
  created_at: string
  updated_at: string
  parsed_ical: ReturnType<typeof vCalendarParser>
  aligned: boolean
}

export interface AlertAttributes {
  entity_id: string
  entity_type: string
  kind: string
  message: string
  priority: string
  state: { name: string; action: string }
  transitions: { name: string; action: string }[]
}

export interface PreoccupationAttributes {
  created_at: string
  ending_at: string
  ending_on: string
  ical: string
  recurring: boolean
  starting_at: string
  state: { name: string; action: string }
  transitions: { name: string; action: string }[]
  time_zone: string
  updated_at: string
  rrule: null | string
  alerted: boolean
  transitioning: boolean
}

export interface ApplicantAttributes {
  administered_opening: null | string
  applied_opening: { id: string }
  created_at: string
  email_address: string
  first_name: string
  full_name: string
  id: string
  last_name: string
  organization: { id: string }
  phone_number: string
  receive_text: boolean
  status: string
  type: 'applicants'
  updated_at: string
  utm_campaign: null | string
  utm_content: null | string
  utm_medium: null | string
  utm_source: null | string
  utm_term: null | string
}

export interface ScheduleAttributes {
  created_at: string
  description: string | null
  id: string
  summary: string
}

export interface InterviewAttributes {
  created_at: string
  description: string | null
  ending_at: string
  local_ending_at: string
  ending_on: string | null
  ical: string
  id: string
  starting_at: string
  local_starting_at: string
  state: { name: string; action: string }
  transitions: { name: string; action: string }[]
  summary: string
  time_zone: string
  type: string
  updated_at: string
}

export interface IncludedAlert extends Alert {
  organizization: { id: string }
}
export type Alert = KrowIdentity<'calendar'> & AlertAttributes
export type AlertDocument = KrowDocument<'calendar', AlertAttributes>
export type AlertCollectionResult = KrowResponse<IncludedAlert[]>
export type AlertCollectionResponse = KrowResponse<AlertAttributes>
export type AlertResponse = KrowResponse<AlertDocument>
export type AlertResult = KrowResponse<IncludedAlert>

export interface IncludedCalendar extends Calendar {}
export type Calendar = KrowIdentity<'calendar'> & CalendarAttributes
export type CalendarDocument = KrowDocument<'calendar', CalendarAttributes>
export type CalendarCollectionResult = KrowResponse<IncludedCalendar[]>
export type CalendarCollectionResponse = KrowResponse<CalendarAttributes>
export type CalendarResponse = KrowResponse<CalendarDocument>
export type CalendarResult = KrowResponse<IncludedCalendar>

export interface IncludedPreoccupation extends Preoccupation {}
export type Preoccupation = KrowIdentity<'preoccupations'> & PreoccupationAttributes
export type PreoccupationDocument = KrowDocument<'preoccupations', PreoccupationAttributes>
export type PreoccupationCollectionResult = KrowResponse<IncludedPreoccupation[]>
export type PreoccupationCollectionResponse = KrowResponse<PreoccupationAttributes>
export type PreoccupationResponse = KrowResponse<PreoccupationDocument>
export type PreoccupationResult = KrowResponse<IncludedPreoccupation>

export interface IncludedApplicant extends Applicant {}
export type Applicant = KrowIdentity<'applicants'> & ApplicantAttributes
export type ApplicantDocument = KrowDocument<'applicants', ApplicantAttributes>
export type ApplicantCollectionResult = KrowResponse<IncludedApplicant[]>
export type ApplicantCollectionResponse = KrowResponse<ApplicantAttributes>
export type ApplicantResponse = KrowResponse<ApplicantDocument>
export type ApplicantResult = KrowResponse<IncludedApplicant>

export interface IncludedSchedule extends Interview {}
export interface IncludedInterview extends Interview {
  applicant: Applicant
  position: Position
  opening: Opening
}
export type Interview = KrowIdentity<'applicants'> & InterviewAttributes
export type InterviewDocument = KrowDocument<'applicants', InterviewAttributes>
export type InterviewCollectionResult = KrowResponse<IncludedInterview[]>
export type InterviewCollectionResponse = KrowResponse<InterviewAttributes>
export type InterviewResponse = KrowResponse<InterviewDocument>
export type InterviewResult = KrowResponse<IncludedInterview>

export type Schedule = KrowIdentity<'applicants'> & ScheduleAttributes
export type ScheduleDocument = KrowDocument<'applicants', ScheduleAttributes>
export type ScheduleCollectionResult = KrowResponse<IncludedSchedule>
export type ScheduleCollectionResponse = KrowResponse<ScheduleAttributes>
export type ScheduleResponse = KrowResponse<ScheduleDocument>
export type ScheduleResult = KrowResponse<IncludedSchedule>

export const calendarApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOneCalendar: build.query<
      CalendarResult,
      {
        localityId: string
        localityType: 'regions' | 'locations'
        startingOn: string
        endingOn: string | undefined
      }
    >({
      query({ localityId, localityType, startingOn, endingOn }) {
        return {
          method: 'GET',
          url: `${localityType}/${localityId}/calendar`,
          params: {
            filter: {
              starting_on: startingOn,
              ending_on: endingOn,
            },
          },
        }
      },
      providesTags: (result) => [{ id: result?.data.id, type: 'Calendar' }],
    }),
    createPreoccupation: build.mutation<PreoccupationResult, { calendarId?: string; ical: string }>(
      {
        query(scope) {
          const { calendarId, ...attributes } = scope
          return {
            method: 'POST',
            url: `calendars/${calendarId}/preoccupations`,
            body: {
              data: {
                attributes,
              },
            },
          }
        },
        transformResponse: (response: PreoccupationResponse): PreoccupationResult => {
          return {
            ...response,
            data: deserialize(response),
          }
        },
        invalidatesTags: (result) => [{ type: 'Preoccupation' }],
      }
    ),
    deletePreoccupation: build.mutation<void, string>({
      query(id) {
        return {
          method: 'DELETE',
          url: `preoccupations/${id}`,
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'Preoccupation', id }],
    }),
    getOnePreoccupation: build.query<PreoccupationResult, string>({
      query(id) {
        return {
          method: 'GET',
          url: `preoccupations/${id}`,
        }
      },
      transformResponse: (response: PreoccupationResponse): PreoccupationResult => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result) => [{ type: 'Preoccupation', id: result?.data.id }],
    }),
    getPreoccupationAlert: build.query<AlertCollectionResult, string>({
      query(id) {
        return {
          method: 'GET',
          url: `preoccupations/${id}/alerts`,
        }
      },
      transformResponse: (response: AlertCollectionResponse): AlertCollectionResult => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
    }),
    allPreoccupations: build.query<PreoccupationCollectionResult, { calendarId?: string }>({
      query(scope) {
        const { calendarId } = scope
        return {
          method: 'GET',
          url: `calendars/${calendarId}/preoccupations`,
          params: {
            sort: '-created_at',
          },
        }
      },
      transformResponse: (
        response: PreoccupationCollectionResponse
      ): PreoccupationCollectionResult => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result) => (result?.data ? providesList(result.data, 'Preoccupation') : []),
    }),
    getOneCalendarInterview: build.query<any, string>({
      query(id) {
        return {
          method: 'GET',
          url: `interviews/${id}`,
          params: {
            included: [
              'applicant',
              'applicant.opening',
              'applicant.opening.position',
              'applicant.opening.locality',
            ],
          },
        }
      },
      transformResponse: (response: InterviewResponse): InterviewResult => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
    }),
    allSchedules: build.query<ScheduleCollectionResult, { organizationId?: string }>({
      query(scope) {
        const { organizationId, ...params } = scope
        return {
          method: 'GET',
          url: `organizations/${organizationId}/schedules`,
          params,
        }
      },
      transformResponse: (response: ScheduleCollectionResponse): ScheduleCollectionResult => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result) => (result?.data ? providesList(result.data, 'Schedule') : []),
    }),
    allInterviews: build.query<
      InterviewCollectionResult,
      { calendarId?: string; ending_on: string; starting_on: string }
    >({
      query(scope) {
        const { calendarId, starting_on, ending_on } = scope
        return {
          method: 'GET',
          url: `calendars/${calendarId}/interviews`,
          params: {
            included: ['applicant', 'applicant.opening', 'applicant.opening.position'],
            filter: { starting_on, ending_on },
            sort: ['starting_at'],
          },
        }
      },
      transformResponse: (response: InterviewCollectionResponse): InterviewCollectionResult => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result) => (result?.data ? providesList(result.data, 'Interview') : []),
    }),
  }),

  overrideExisting: false,
})

export const {
  useAllPreoccupationsQuery,
  useGetOneCalendarQuery,
  useCreatePreoccupationMutation,
  useDeletePreoccupationMutation,
  useGetOneCalendarInterviewQuery,
  useAllInterviewsQuery,
  useAllSchedulesQuery,
  useGetOnePreoccupationQuery,
  useGetPreoccupationAlertQuery,
} = calendarApi
