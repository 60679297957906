import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  list: {
    padding: theme.spacing(1, 0),
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
}))

export { useStyles }
