import { api } from '../store.api'
import { ListQueryParams } from 'store/store.types'
import { providesList } from 'store/store.utils'
import { KrowIdentity, KrowDocument, KrowResponse, KrowPartialResource } from 'store/industry'

export interface DirectionAttributes {
  starting_at: string
  ending_at: string
  ical: string
}

export type Direction = KrowIdentity<'schedules'> & DirectionAttributes
export type DirectionPartialResource = KrowPartialResource<DirectionAttributes>
export type DirectionDocument = KrowDocument<'schedules', DirectionAttributes>
export type DirectionResponse = KrowResponse<DirectionDocument>
export type DirectionCollectionResponse = KrowResponse<DirectionDocument[]>

export const directionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    allDirections: build.query<
      DirectionCollectionResponse,
      { type: 'positions' | 'openings'; id: string } & ListQueryParams
    >({
      query({ type, id, ...params }) {
        return {
          method: 'GET',
          url: `${type}/${id}/directions`,
          params,
        }
      },
      providesTags: (result) => (result ? providesList(result.data, 'Directions') : []),
    }),
    updateDirection: build.mutation<
      null,
      { type: 'positions' | 'openings'; id: string; direction: DirectionDocument }
    >({
      query({ type, id, direction }) {
        return {
          method: 'PUT',
          url: `${type}/${id}/directions/${direction.id}`,
        }
      },
    }),
    removeDirection: build.mutation<
      null,
      { type: 'positions' | 'openings'; id: string; direction: DirectionDocument }
    >({
      query({ type, id, direction }) {
        return {
          method: 'DELETE',
          url: `${type}/${id}/directions/${direction.id}`,
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const { useAllDirectionsQuery, useUpdateDirectionMutation, useRemoveDirectionMutation } =
  directionsApi
