import { api } from '../store.api'
import { providesList } from 'store/store.utils'
import { KrowCollection } from '../industry'
import { ListQueryParams } from 'store/store.types'

export interface Assignment {
  name: string
  created_at: string
  updated_at: string
}

export type ClassificationCollectionResult = KrowCollection<'classification', Assignment>
export type MarketCollectionResult = KrowCollection<'market', Assignment>

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    listClassifications: build.query<ClassificationCollectionResult, ListQueryParams>({
      query({ search, sort, page = { number: 1, size: 25 } }) {
        return {
          method: 'GET',
          url: 'classifications',
          params: {
            search,
            sort,
            page,
          },
        }
      },
      providesTags: (result) => (result?.data ? providesList(result?.data, 'Classification') : []),
    }),
    listMarkets: build.query<ClassificationCollectionResult, ListQueryParams>({
      query({ search, sort, page = { number: 1, size: 25 } }) {
        return {
          method: 'GET',
          url: 'markets',
          params: {
            search,
            sort,
            page,
          },
        }
      },
      providesTags: (result) => (result?.data ? providesList(result?.data, 'Market') : []),
    }),
  }),
  overrideExisting: false,
})

export const { useListClassificationsQuery, useListMarketsQuery } = extendedApi
