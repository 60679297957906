export interface ApiErrorDetail {
  code: string
  status: number
  detail: string
  source: {
    pointer: string
  }
}

export type ApiErrorDetailResponse = { data: ApiErrorDetail[] }

export function addServerErrors<T>(
  fieldsNames: (keyof T)[],
  errors: ApiErrorDetail[] = [],
  setError: (fieldName: keyof T, error: { type: string; message: string }) => void
) {
  return errors.reduce<boolean>((isErrorSet, error) => {
    const [pointer] = error?.source?.pointer?.split('/')?.reverse() as (keyof T)[]
    if (fieldsNames.includes(pointer)) {
      setError(pointer, {
        type: 'server',
        message: error.detail,
      })
      return true
    }
    return isErrorSet
  }, false)
}

const isDataResponse = (error: unknown): error is { data: unknown } => {
  return typeof error === 'object' && error !== null && 'data' in error
}

const isApiErrorDetail = (error: unknown) => {
  return (
    typeof (error as ApiErrorDetail).code === 'string' &&
    typeof (error as ApiErrorDetail).status === 'number' &&
    typeof (error as ApiErrorDetail).detail === 'string' &&
    typeof (error as ApiErrorDetail).source === 'object'
  )
}

export function isApiError(error: unknown): error is ApiErrorDetailResponse {
  if (isDataResponse(error) && Array.isArray(error.data)) {
    const [first] = error.data
    return !!first && isApiErrorDetail(first)
  }
  return false
}
