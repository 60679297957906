import React, { useState } from 'react'
import { useDebounce } from 'use-debounce'
import { ControllerRenderProps, ControllerFieldState, UseFormStateReturn } from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { useListClassificationsQuery } from 'store/assignments/assignments.api'

export type FormMarketAutocompleteProperties = {
  field: ControllerRenderProps<any, string>
  fieldState: ControllerFieldState
  formState: UseFormStateReturn<any>
}

export default function FormClassificationAutocomplete({
  field,
  fieldState,
}: FormMarketAutocompleteProperties) {
  const [_search, _setSearch] = useState<string>('')
  const [search] = useDebounce(_search, 400)

  const { data = { data: [] }, isLoading } = useListClassificationsQuery({ search })

  const options = React.useMemo(() => {
    return (
      data?.data?.map((market) => ({
        name: market.attributes.name,
        id: market.id,
      })) || []
    )
  }, [data?.data])

  return (
    <Autocomplete
      {...field}
      defaultValue=""
      fullWidth
      sx={{ marginBottom: 3 }}
      value={field.value || null}
      options={options}
      getOptionLabel={(option) => option?.name || ''}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      loading={isLoading}
      onChange={(event, option) => {
        field.onChange(option)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Classification"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              </React.Fragment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          onChange={(event) => _setSearch(event.target.value)}
          error={!!fieldState.error?.message}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}
