import { createSlice, createAsyncThunk, SliceCaseReducers, PayloadAction } from '@reduxjs/toolkit'
import { Resource } from '../store.types'
import * as PositionService from './position.service'

export interface Attributes {
  name: string
  description: string
  created_at: string
  updated_at: string
  organization_id: string
  external_id: string | null
  total: number
  pending_responses: number
  accepted_responses: number
  rejected_responses: number
  scheduled_responses: number
  unscheduled_responses: number
}

export interface Position extends Attributes {
  id: string
  type: 'Position'
}

export interface PositionResponse extends Resource<Attributes> {
  id: string
  type: 'Position'
}

export type PositionState = {
  activePositionId: string
  byId: { [key: string]: Position }
  result: string[]
  isLoaded: boolean
}

export const getPositions = createAsyncThunk('positions/get', async (params, ctx) => {
  return PositionService.get()
})

export const updatePosition = createAsyncThunk(
  'positions/update',
  async (Position: Partial<Position>, ctx) => {
    return PositionService.update(Position)
  }
)

export const createPosition = createAsyncThunk(
  'positions/create',
  async (attributes: Partial<Attributes>, ctx) => {
    return PositionService.create(attributes)
  }
)

export const deletePosition = createAsyncThunk('positions/delete', async (id: string, ctx) => {
  return PositionService.destroy(id)
})

export const total = (position: Position) => {
  return Object.assign(position, {
    total:
      (position.pending_responses || 0) +
      (position.rejected_responses || 0) +
      (position.accepted_responses || 0) +
      (position.scheduled_responses || 0) +
      (position.unscheduled_responses || 0),
  })
}

const PositionsState = createSlice<PositionState, SliceCaseReducers<PositionState>>({
  name: 'positions',
  initialState: {
    activePositionId: '',
    byId: {},
    result: [],
    isLoaded: false,
  },
  reducers: {
    setActivePosition(state, action: PayloadAction<string>) {
      state.activePositionId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPositions.fulfilled, (state, action) => {
      state.isLoaded = true
      action.payload.forEach((position, i) => {
        if (i === 0) {
          state.activePositionId = position.id
        }
        state.byId[position.id] = {
          id: position.id,
          type: position.type,
          ...position.attributes,
          // total: total(position.attributes),
        }
        if (!state.result.includes(position.id)) {
          state.result.push(position.id)
        }
      })
    })

    builder.addCase(getPositions.rejected, (state, action) => {
      state.isLoaded = true
    })

    builder.addCase(updatePosition.fulfilled, (state, action) => {
      state.byId[action.payload.id] = {
        id: action.payload.id,
        type: action.payload.type,
        ...action.payload.attributes,
        // total: total(action.payload.attributes),
      }
    })

    builder.addCase(createPosition.fulfilled, (state, action) => {
      state.byId[action.payload.id] = {
        id: action.payload.id,
        type: action.payload.type,
        ...action.payload.attributes,
        // total: total(action.payload.attributes),
      }
      state.result.push(action.payload.id)
    })

    builder.addCase(updatePosition.rejected, (state, action) => {})

    builder.addCase(deletePosition.fulfilled, (state, action) => {
      delete state.byId[action.meta.arg]
      state.result = state.result.filter((id) => id !== action.meta.arg)
    })
  },
})

export const { setActivePosition } = PositionsState.actions

export default PositionsState
