import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store'

const organizationIds = (state: RootState) => state.organizations.result
const organizationsById = (state: RootState) => state.organizations.byId
const mappedOrganizations = (
  ids: ReturnType<typeof organizationIds>,
  byId: ReturnType<typeof organizationsById>
) => {
  return ids.map((id) => byId[id])
}
const activeIdSelector = (state: RootState) => state.organizations.activeOrganizationId

export const activeOrganizationSelector = createSelector(
  organizationsById,
  activeIdSelector,
  (organizations, activeId) => organizations[activeId]
)
export const organizationsSelector = createSelector(
  organizationIds,
  organizationsById,
  mappedOrganizations
)
