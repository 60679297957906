import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import MatToolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useStyles from './AppToolbar.styles'

interface AppToolbarProps {
  logo: string
  position: string
}

function AppToolbar(props: AppToolbarProps) {
  const { logo, position } = props
  const classes = useStyles()

  return (
    <AppBar id="app-toolbar" className={classes.root} color="transparent" position="relative">
      <MatToolbar className={classes.toolbar}>
        <Box alignItems="center" display="flex">
          {logo && <img className={classes.logo} src={logo} alt="logo" height="100%" />}
          <Typography variant="h3">Quick Apply Process</Typography>
        </Box>
        <Box alignItems="flex-end" display="flex" flexDirection="column">
          <Typography>{position}</Typography>
        </Box>
      </MatToolbar>
    </AppBar>
  )
}

export default AppToolbar
