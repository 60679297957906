import { createStyles, makeStyles } from '@mui/styles'
import { lighten } from '@mui/material/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    grid: {
      display: 'grid',
      'grid-template-columns': 'repeat( auto-fill, minmax(150px, 1fr) )',
      gridGap: theme.spacing(1.25),
      marginTop: theme.spacing(2),

      [theme.breakpoints.up('md')]: {
        gridGap: theme.spacing(2),
      },
    },
    root: {
      border: `3px solid transparent`,
      cursor: 'pointer',

      '&:hover': {
        background: lighten(theme.palette.primary.main, 0.95),
      },
    },
    selected: {
      border: `3px solid ${theme.palette.primary.main}`,
    },
    checkbox: {
      opacity: 0,
      height: 0,
      margin: 0,
      position: 'absolute',
    },
    icon: {
      fill: '#95A1AC',
      transition: 'fill 0.3s',
    },
    active: {
      fill: '#222222',
    },
    secondary: {
      alignItems: 'center',
      textAlign: 'left',
    },
  })
)

export default useStyles
