// @ts-nocheck
import axios from 'util/axios'
import { organizationId, locationId } from '../path'
import { Attributes, Params, ResponseResource } from './index'
import { ListQueryParams, Meta } from 'store/store.types'

export async function create(attributes: Partial<Attributes>) {
  return axios
    .post<{ data: ResponseResource }>(`/organizations/${organizationId()}/responses`, {
      data: { attributes },
    })
    .then((response) => response.data.data)
}

export async function update(id: string, attributes: Partial<Attributes>) {
  return axios
    .patch<{ data: ResponseResource }>(`/organizations/${organizationId()}/responses/${id}`, {
      data: { attributes },
    })
    .then((response) => response.data.data)
}

export async function get(params: ListQueryParams | undefined) {
  const { search, filter, ...rest } = params || {}
  return axios
    .get<{ data: ResponseResource[]; meta: Meta }>(`/organizations/${organizationId()}/responses`, {
      params: {
        ...rest,
        'search[first_name]': search,
        'search[last_name]': search,
        'search[email_address]': search,
        'filter[availability_id]': filter?.availability_id,
        'filter[position_id]': filter?.position_id,
        'filter[status]': filter?.status,
        'filter[location_id]': locationId(),
      },
    })
    .then((response) => response?.data)
}

export async function getWithQuery(params: Partial<Params> = {}) {
  return axios
    .get<{ data: ResponseResource[] }>(`/organizations/${organizationId()}/responses`, {
      params: {
        'filter[availability_id]': params.availability_id,
        'filter[opening_id]': params.opening_id,
        'filter[response_id]': params.response_id,
        'filter[location_id]': params.location_id,
        'filter[position_id]': params.position_id,
      },
    })
    .then((response) => response.data.data)
}
