import React, { useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { ControllerRenderProps, ControllerFieldState, UseFormStateReturn } from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { useListCountriesQuery } from 'store/country/country.api'

export type FormRegionAutocompleteProperties = {
  field: ControllerRenderProps<any, string>
  fieldState: ControllerFieldState
  formState: UseFormStateReturn<any>
  variant: 'standard' | 'filled' | 'outlined' | undefined
}

export default function FormCountryAutocompleteNew(props: FormRegionAutocompleteProperties) {
  const { field, fieldState, variant } = props
  const { onChange, value, ...rest } = field
  const [_search, _setSearch] = useState<string>('')
  const [search] = useDebounce(_search, 400)
  const { data = { data: [] }, isLoading } = useListCountriesQuery({ search })
  const options = React.useMemo(() => {
    return (
      data?.data?.map((country) => ({
        name: country.attributes.name,
        areaType: country.attributes.area,
        id: country.id,
      })) || []
    )
  }, [data?.data])

  const newVaule = useMemo(() => options.find((p) => p.id === value) ?? null, [options, value])

  return (
    <Autocomplete
      {...rest}
      fullWidth
      sx={{ marginBottom: 3 }}
      options={options}
      value={newVaule}
      getOptionLabel={(option) => option?.name || ''}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      loading={isLoading}
      onChange={(event, option) => {
        onChange(option?.id ?? null)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Country"
          variant={variant}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              </React.Fragment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          onChange={(event) => _setSearch(event.target.value)}
          error={!!fieldState.error?.message}
          helperText={fieldState.error?.message}
        />
      )}
    />
  )
}
