import { createStyles, makeStyles } from '@mui/styles'
import { lighten } from '@mui/material/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    grid: {
      display: 'grid',
      'grid-template-columns': 'repeat( auto-fill, minmax(150px, 1fr) )',
      gridGap: theme.spacing(1.25),
      marginTop: theme.spacing(2),

      [theme.breakpoints.up('md')]: {
        gridGap: theme.spacing(2),
      },
    },
    root: {
      color: theme.palette.common.white,
      cursor: 'pointer',
      position: 'relative',
      border: `3px solid transparent`,
      textAlign: 'center',

      '&:not(:first-child)::before': {
        background: theme.palette.grey['200'],
        content: '""',
        position: 'absolute',
        display: 'block',
        bottom: 0,
        top: 0,
        left: '-11px',
        width: '1px',
      },
    },
    selected: {
      '&$primary': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 2px 0 0 ${theme.palette.primary.dark}, 0 3px 10px 0 rgba(36,36,36,0.2)`,
      },
      '&$secondary': {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: `0 2px 0 0 ${theme.palette.secondary.dark}, 0 3px 10px 0 rgba(36,36,36,0.2)`,
      },
      '&$tertiary': {
        backgroundColor: theme.palette.info.main,
        boxShadow: `0 2px 0 0 ${theme.palette.info.dark}, 0 3px 10px 0 rgba(36,36,36,0.2)`,
      },
    },
    checkbox: {
      opacity: 0,
      height: 0,
      margin: 0,
      position: 'absolute',
    },
    icon: {
      fill: '#95A1AC',
      transition: 'fill 0.3s',
    },
    active: {
      fill: '#222222',
    },
    primary: {
      '&:not($selected):hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.7),
      },
    },
    secondary: {
      '&:not($selected):hover': {
        backgroundColor: lighten(theme.palette.secondary.main, 0.7),
      },
    },
    tertiary: {
      '&:not($selected):hover': {
        backgroundColor: lighten(theme.palette.info.main, 0.7),
      },
    },
  })
)

export default useStyles
