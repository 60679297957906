import { Tooltip } from 'shared/components'
import { IconButton } from '@mui/material'

function ActionIcon({ Icon, CustomIcon = null, title, backgroundColor, width = 20, height = 20 }) {
  return (
    <Tooltip title={title} placement="top">
      <IconButton sx={{ backgroundColor, boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.15)' }}>
        {CustomIcon ? <CustomIcon /> : <Icon width={width} height={height} />}
      </IconButton>
    </Tooltip>
  )
}
export default ActionIcon
