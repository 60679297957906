import { createSlice, createAsyncThunk, SliceCaseReducers, PayloadAction } from '@reduxjs/toolkit'
import { Resource } from '../store.types'
import * as LocationService from './location.service'

export interface Attributes {
  website_url: string
  subdomain: string
  name: string
  address: string
  unit: string
  city: string
  state: string
  postal_code: string
  time_zone: string
  lat: number
  lng: number
  external_id: string
  phone_number: string
  coords: {
    latitude: number | null
    longitude: number | null
  }
}

export interface InterimLocation extends Attributes {
  id: string
  type: 'Location'
}

export type Location = Omit<InterimLocation, 'coords'> & Attributes['coords']

export interface LocationResponse extends Resource<Attributes> {
  id: string
  type: 'Location'
}

export type LocationState = {
  activeLocationId: string
  byId: { [key: string]: Location }
  result: string[]
  isLoaded: boolean
}

export type LocationQueryParams = { zipcode?: string } | undefined

export const getLocations = createAsyncThunk(
  'locations/get',
  async (params: LocationQueryParams = {}, ctx) => {
    return LocationService.get(params)
  }
)

export const updateLocation = createAsyncThunk(
  'locations/update',
  async (location: Location, ctx) => {
    return LocationService.update(location)
  }
)

export const createLocation = createAsyncThunk(
  'locations/create',
  async (attributes: Attributes, ctx) => {
    return LocationService.create(attributes)
  }
)

export const deleteLocation = createAsyncThunk('locations/delete', async (id: string, ctx) => {
  return LocationService.destroy(id)
})

const locationsState = createSlice<LocationState, SliceCaseReducers<LocationState>>({
  name: 'locations',
  initialState: {
    activeLocationId: '',
    byId: {},
    result: [],
    isLoaded: false,
  },
  reducers: {
    setActiveLocation(state, action: PayloadAction<string>) {
      state.activeLocationId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.isLoaded = true
      state.result = action.payload.map((location, i) => {
        state.byId[location.id] = {
          id: location.id,
          type: location.type,
          ...location.attributes,
          ...location.attributes.coords,
        }

        if (i === 0) {
          state.activeLocationId = location.id
        }

        return location.id
      })
    })

    builder.addCase(getLocations.rejected, (state, action) => {
      state.isLoaded = true
    })

    builder.addCase(updateLocation.fulfilled, (state, action) => {
      state.byId[action.payload.id] = {
        id: action.payload.id,
        type: action.payload.type,
        ...action.payload.attributes,
        ...action.payload.attributes.coords,
      }
    })

    builder.addCase(createLocation.fulfilled, (state, action) => {
      state.byId[action.payload.id] = {
        id: action.payload.id,
        type: action.payload.type,
        ...action.payload.attributes,
        ...action.payload.attributes.coords,
      }
      state.result.push(action.payload.id)
    })

    builder.addCase(updateLocation.rejected, (state, action) => {})

    builder.addCase(deleteLocation.fulfilled, (state, action) => {
      delete state.byId[action.meta.arg]
      state.result = state.result.filter((id) => id !== action.meta.arg)
    })
  },
})

export const { setActiveLocation } = locationsState.actions

export default locationsState
