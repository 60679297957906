import { createSlice, SliceCaseReducers, PayloadAction } from '@reduxjs/toolkit'

export interface ScopeState {
  organizationId: string
  localityId: string
  regionId: string
}

const ScopesSlice = createSlice<ScopeState, SliceCaseReducers<ScopeState>>({
  name: 'scope',
  initialState: {
    organizationId: '',
    localityId: '',
    regionId: '',
  },
  reducers: {
    scopeOrganization(state, action: PayloadAction<string>) {
      state.organizationId = action.payload
    },
    scopeLocality(state, action: PayloadAction<string>) {
      state.localityId = action.payload
    },
  },
})

export const { scopeOrganization, scopeLocality } = ScopesSlice.actions

export default ScopesSlice
