import { FC, memo } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './krow-wysiwyg-editor.css'

export interface KrowWysiwygEditorProps {
  value: string
  onChange: (newValue: string) => void
  children: never
}

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
}

const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'link',
  'image',
]

const KrowWysiwygEditor: FC<KrowWysiwygEditorProps> = (props) => {
  return <ReactQuill theme="snow" {...props} modules={modules} formats={formats} />
}

export default memo(KrowWysiwygEditor)
