import { Box, Typography } from '@mui/material'
import { theme } from 'theme'

import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material'

type KrowRollingStatisticIndicatorProperties = {
  value: number | null
  change: number | null
}

export default function KrowRollingStatisticIndicator({
  value,
  change,
}: KrowRollingStatisticIndicatorProperties) {
  if (value === null) return <Typography>-</Typography>
  else if (change === null) return <Typography>{value}</Typography>

  const color =
    change === 0
      ? theme.palette.text.primary
      : Math.abs(change) === change
      ? theme.palette.success.main
      : theme.palette.error.main

  const arrow =
    change === 0 ? (
      <Typography>-</Typography>
    ) : Math.abs(change) === change ? (
      <ArrowDropUp />
    ) : (
      <ArrowDropDown />
    )

  return (
    <Box textAlign="center" display="flex" color={color}>
      {arrow}
      <Typography display="inline">{value}</Typography>
      <Typography display="inline" marginLeft="8px">
        ({change}%)
      </Typography>
    </Box>
  )
}
