import React from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg'
import useStyles from './CXRadioCard.styles'

interface RadioCardProps<T> {
  image_url?: string
  className?: string
  setSelection: (selection: string) => void
  selection: T | string | null
  value: string
  text: string
  name: string
  secondary?: boolean
}

function RadioCard<T>(props: RadioCardProps<T>) {
  const { image_url, value, text, name, className, selection, setSelection, secondary } = props
  const classes = useStyles()

  return (
    <Box
      className={clsx(classes.root, className, {
        [classes.selected]: selection === value,
        [classes.secondary]: secondary,
      })}
      alignItems="center"
      justifyContent="flex-start"
      bgcolor="white"
      display="flex"
      textAlign="center"
      component="label"
      borderRadius="8px"
      boxShadow={3}
      p={2}
      tabIndex={1}
    >
      {image_url && (
        <Box mr={2} maxWidth="60px">
          <img
            src={image_url}
            alt={text}
            className={clsx(classes.icon, { [classes.active]: selection === value })}
          />
        </Box>
      )}

      <Box fontSize="16px" fontWeight="bold" component="span" display="flex" flex="1">
        {text}
      </Box>

      <input
        className={classes.checkbox}
        checked={value === selection}
        name={name}
        type="radio"
        value={value}
        onChange={(e) => setSelection(e.target.value)}
      />
      {value === selection && <CheckCircle className={classes.selectedIcon} />}
    </Box>
  )
}

export default RadioCard
