import { api } from '../store.api'

export const ssoApi = api.injectEndpoints({
  endpoints: (build) => ({
    verifySSO: build.query({
      query({ subdomain }) {
        return {
          method: 'GET',
          url: `sso/organizations/${subdomain}`,
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const { useVerifySSOQuery } = ssoApi
