import React from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg'
import useStyles from './CXSelectMultiple.styles'

interface SelectMultipleProps {
  image_url?: string
  className?: string
  setSelection: (selection: string) => void
  selection: Set<string>
  value: string
  text: string
  name: string
  secondary?: boolean
}

function SelectMultiple(props: SelectMultipleProps) {
  const { image_url, value, text, name, className, selection, setSelection, secondary } = props
  const classes = useStyles()

  return (
    <Box
      className={clsx(classes.root, className, {
        [classes.selected]: selection.has(value),
        [classes.secondary]: secondary,
      })}
      alignItems="center"
      justifyContent="flex-start"
      bgcolor="white"
      display="flex"
      textAlign="center"
      component="label"
      borderRadius="8px"
      boxShadow={3}
      p={2}
      tabIndex={1}
    >
      {image_url && (
        <Box mr={2} maxWidth="60px">
          <img
            src={image_url}
            alt={text}
            className={clsx(classes.icon, { [classes.active]: selection.has(value) })}
          />
        </Box>
      )}
      <Box fontSize="16px" fontWeight="bold" component="span">
        {text}
      </Box>

      <input
        className={classes.checkbox}
        checked={selection.has(value)}
        name={name}
        type="checkbox"
        value={value}
        onChange={(e) => setSelection(e.target.value)}
      />
      {selection.has(value) && <CheckCircle className={classes.selectedIcon} />}
    </Box>
  )
}

export default SelectMultiple
