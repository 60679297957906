import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet'
import store from 'store'
import { EnhancedStore } from '@reduxjs/toolkit'
import { Box, CircularProgress } from '@mui/material'
import ReactDOM from 'react-dom'
import { Switch, Route } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import Providers from './Providers'
import { ErrorBoundary } from 'shared/components'
import { Me } from 'store/me'
import { ApplicationPatchProvider } from 'shared/hooks/useApplicationPatch'
import runtimeEnv from 'util/runtimeEnv'
import './index.css'

const Application = React.lazy(() => import('./routes/Application'))
const Routes = React.lazy(() => import('./routes'))
const ExportDownload = React.lazy(() => import('./routes/ExportDownload'))
const env = runtimeEnv()

declare global {
  interface Window {
    google: google.maps.Map
    store: EnhancedStore
    Cypress: boolean
    user: Me['attributes']
  }
}

ReactDOM.render(
  <Suspense
    fallback={
      <Box display="flex" minHeight="100vh" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    }
  >
    <Helmet>
      <script type="text/javascript">{env.REACT_APP_HOTJAR_SCRIPT}</script>
      <script src="https://cdn.jsdelivr.net/npm/@airbrake/browser"></script>
    </Helmet>

    <ErrorBoundary>
      <React.StrictMode>
        <Providers>
          <Switch>
            <Route
              exact
              path="/organizations/:organizationId/questionnaires/:questionnaireId/preview"
            >
              <ApplicationPatchProvider>
                <Application preview={true} />
              </ApplicationPatchProvider>
            </Route>

            <Route exact path="/exports/:exportId">
              <ExportDownload />
            </Route>

            <Routes />
          </Switch>
        </Providers>
      </React.StrictMode>
    </ErrorBoundary>
  </Suspense>,

  document.getElementById('root')
)

window.store = store
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
