import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store'

const positionIds = (state: RootState) => state.positions.result
const positionsById = (state: RootState) => state.positions.byId
const mappedPositions = (
  ids: ReturnType<typeof positionIds>,
  byId: ReturnType<typeof positionsById>
) => {
  return ids.map((id) => byId[id])
}
const activeIdSelector = (state: RootState) => state.positions.activePositionId
const options = (positions: ReturnType<typeof mappedPositions>) =>
  positions.map((position) => ({
    name: position.name,
    value: position.id,
  }))

export const activePositionSelector = createSelector(
  positionsById,
  activeIdSelector,
  (positions, activeId) => positions[activeId]
)
export const positionsSelector = createSelector(positionIds, positionsById, mappedPositions)
export const positionOptions = createSelector(positionsSelector, options)
