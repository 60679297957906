import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

import { IconButtonProps } from '@mui/material/IconButton'

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      color: '#fff',
      background: (props: IconButtonProps) => (theme.palette as any)[props.color || 'primary'].main,
      '&:hover': {
        background: (props: IconButtonProps) =>
          (theme.palette as any)[props.color || 'primary'].dark,
      },
    },
  })
})

export { useStyles }
