import { ReactChild } from 'react'
import { useGetMyProfileQuery } from 'store/me/me.api'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useVerifySSOQuery } from 'store/sso/sso.api'

interface PrivateRouteProps extends RouteProps {
  children: ReactChild
}

const PrivateRoute = ({ children, ...rest }: PrivateRouteProps) => {
  const hostname = window.location.hostname
  const subdomain = hostname.split('.')[0]

  const { isSuccess: isAuthenticated, isLoading: meLoading } = useGetMyProfileQuery()
  const { isSuccess: ssoEnabled, isLoading: ssoLoading } = useVerifySSOQuery(
    { subdomain },
    { skip: isAuthenticated }
  )

  if (ssoLoading) return null

  if (ssoEnabled) {
    return (
      <Route {...rest}>
        <Redirect
          to={{
            pathname: '/sso',
            state: { from: rest.path },
          }}
        />
      </Route>
    )
  }

  return (
    <Route {...rest}>
      {isAuthenticated && !meLoading && !ssoLoading ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: rest.path },
          }}
        />
      )}
    </Route>
  )
}

export default PrivateRoute
