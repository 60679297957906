import React from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import useStyles from './RadioCardSecondary.styles'

interface RadioCardSecondaryProps {
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  className?: string
  value: string
  text: string
  selection: string
  setSelection: (priority: string) => void
  options: string[]
}

function RadioCardSecondary(props: RadioCardSecondaryProps) {
  const { icon: Icon, value, text, className, selection, options, setSelection } = props
  const classes = useStyles()

  return (
    <Box
      bgcolor="background.paper"
      boxShadow={1}
      borderRadius="1px"
      py={1}
      px={2}
      mt={1}
      component="div"
      role="radio-group"
      display="flex"
      minWidth={'100%'}
      justifyContent="space-between"
      aria-label={`${value}-radios`}
    >
      {options.map((option, i) => (
        <Box
          key={`${value}-${option}`}
          className={clsx(classes.root, className, {
            [classes.selected]: selection === option,
            [classes.primary]: i === 0,
            [classes.secondary]: i === 1,
            [classes.tertiary]: i === 2,
          })}
          flex="1"
          maxWidth={`calc(33.3% - 10px)`}
          alignItems="center"
          justifyContent="center"
          bgcolor="white"
          display="flex"
          textAlign="center"
          component="label"
          borderRadius="1px"
          p={2}
          tabIndex={1}
        >
          {Icon && (
            <Icon
              width="20px"
              height="20px"
              className={clsx(classes.icon, { [classes.active]: selection === option })}
            />
          )}

          {selection === option && (
            <Box fontSize="16px" fontWeight="bold" component="span">
              {text}
            </Box>
          )}

          <input
            className={classes.checkbox}
            checked={selection === option}
            name={value}
            type="radio"
            value={option}
            onChange={(e) => setSelection(e.target.value)}
          />
        </Box>
      ))}
    </Box>
  )
}

export default RadioCardSecondary
