import axios from 'util/axios'
import { organizationId, locationId } from '../path'
import { QuestionsResponse } from './index'

export async function relationship(id: string | undefined) {
  return axios
    .get<{ data: QuestionsResponse[] }>(
      `/organizations/${organizationId()}/locations/${id || locationId()}/relationship/questions`
    )
    .then((response) => response.data.data)
}
