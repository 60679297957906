import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store'

const questionnairesById = (state: RootState) => state.questionnaires.byId
const activeIdSelector = (state: RootState) => state.questionnaires.activeQuestionnaireId
export const selectBuilderStep = (state: RootState) => state.questionnaires.activeBuilderStep

export const activeQuestionnaireSelector = createSelector(
  questionnairesById,
  activeIdSelector,
  (questionnaires, activeId) => questionnaires[activeId]
)
