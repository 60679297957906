import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiCalendarPicker-root': {
        width: 320,
      },
      '& .filledTimeInput .MuiInput-root:after': {
        borderBottom: '1px solid #338DF7 !important',
      },
      '& .filledTimeInput .MuiInput-root:before': {
        borderBottom: '1px solid #338DF7 !important',
      },
      flexDirection: 'column',
      '& *': {
        maxHeight: 'none',
      },
      '& .css-epd502': {
        width: '100%',
        margin: '0 0px',
      },
      '& .css-1dozdou': {
        paddingLeft: '0px',
        justifyContent: 'space-between',
        '@media (min-width: 1441px)': {
          justifyContent: 'normal',
        },
      },
      '& .css-165tewp': {
        flexDirection: 'initial',
        minWidth: 320,
      },
      '& .css-b4f1r7': {
        marginRight: 18,
      },
      '& .css-k008qs': {
        '.MuiSvgIcon-root': {
          width: '7.43px',
          height: '4.44px',
        },
      },
      '& .MuiTypography-caption': {
        width: 48,
        height: 35,
        fontWeight: '700',
        color: '#1E2429',
        marginTop: 0,
        '@media (min-width: 1441px)': {
          width: 34,
        },
      },
      '& .MuiCalendarPicker-root div[role=row]': {
        margin: '0',
        maxHeight: '100%',
        fontSize: '14px',
        color: '#1E2429',
        borderBottom: '1px solid #DBE2E7',
        '& .Mui-disabled': {
          backgroundColor: '#F1F4F8',
          color: '#8B97A2',
        },
      },
      '& .MuiCalendarPicker-root div[role=row]:last-of-type': {
        borderBottom: '0',
      },
      '& .PrivatePickersToolbar-dateTitleContainer svg': {
        display: 'none',
      },
      '& .PrivatePickersToolbar-dateTitleContainer': {
        paddingBottom: '8px',
        borderBottom: '1px solid #000000',
      },
      '& .calendarWithValue .PrivatePickersToolbar-dateTitleContainer': {
        paddingBottom: '8px',
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
      '& .PrivatePickersToolbar-root': {
        paddingLeft: '14px',
        marginTop: '-16px',
      },
      '& .PrivatePickersToolbar-root h4': {
        fontSize: '16px',
      },
      '& .MuiCalendarPicker-root div[role=row] div:first-child': {
        borderLeft: '0',
      },
      '& .MuiCalendarPicker-root div[role=row]:first-child': {
        borderTop: '0',
      },
      '& .MuiCalendarPicker-root div[role=row] div:last-child': {
        borderRight: '0',
      },
      '& .MuiGrid-item': {
        borderLeft: 'none !important',
      },
      calendarNavBtn: {
        backgroundColor: '#0099cc !important',
      },
      '& .PrivatePickersSlideTransition-root': {
        border: '1px solid #DBE2E7',
        overflow: 'hidden',
        borderRadius: 8,
        minHeight: 245,
      },
      '& .css-18puooo': {
        '@media (min-width: 1441px)': {
          width: 5,
        },
      },
    },
    sectionBorderRight: {
      minHeight: '80vh',
    },
    selectedDayPaper: {
      width: '75px !important',
      height: '48px',
      backgroundColor: `${theme.palette.primary.main}`,
      borderRight: '1px solid #dbe2e7',
      color: '#ffffff',
      boxShadow: 'none',
      borderRadius: 0,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '@media (min-width: 1441px)': {
        width: '43px !important',
        height: '35.38px',
      },
    },
    calendarArrowButton: {
      boxShadow: '0 1px 2px 0 rgba(0,0,0,0.35)',
      background: '#ffffff',
      '&:hover': { background: '#eeeeee' },
      '@media (min-width: 1441px)': {
        boxShadow: 'none',
        width: 30,
        height: 30,
        minHeight: 30,
        '&::active': {
          boxShadow: 'none',
          background: 'transparent',
        },
        '&:hover': { background: 'transparent' },
        '& .MuiSvgIcon-root': {
          width: 14,
        },
      },
    },
    todayPaper: {
      width: '75px !important',
      height: '48px',
      backgroundColor: '#ffffff',
      boxShadow: 'none',
      borderRadius: 0,
      padding: '0px',
      cursor: 'pointer',
      borderRight: '1px solid #DBE2E7',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      '@media (min-width: 1441px)': {
        width: '43px !important',
        height: '35px',
      },
      '&::before': {
        position: 'absolute',
        content: '""',
        height: '100%',
        width: '100%',
        border: '1px solid #95A1AC',
        borderRadius: 32,
      },
    },
    todayDisabledPaper: {
      width: '75px !important',
      height: '48px',
      backgroundColor: '#F1F4F8',
      color: '#8B97A2',
      boxShadow: 'none',
      borderRadius: 0,
      padding: '1px',
      cursor: 'pointer',
      border: '1px solid #999999 !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '@media (min-width: 1441px)': {
        width: '43px !important',
        height: '35.38px',
      },
    },
    normalDayPaper: {
      width: '75px !important',
      height: '48px',
      backgroundColor: '#ffffff',
      boxShadow: 'none',
      borderRadius: 0,
      cursor: 'pointer',
      borderRight: '1px solid #DBE2E7',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '@media (min-width: 1441px)': {
        width: '43px !important',
        height: '35.38px',
      },
    },
    disabledDayPaper: {
      width: '75px !important',
      height: '48px',
      backgroundColor: '#F1F4F8',
      boxShadow: 'none',
      borderRadius: 0,
      cursor: 'pointer',
      borderRight: '1px solid #DBE2E7',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '@media (min-width: 1441px)': {
        width: '43px !important',
        height: '35.38px',
      },
    },
    notInThisMonthDayPaper: {
      width: '75px !important',
      height: '48px',
      backgroundColor: '#FCFCFC !important',
      boxShadow: 'none',
      borderRadius: 0,
      color: '#DBE2E7',
      borderRight: '1px solid #DBE2E7',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '@media (min-width: 1441px)': {
        width: '43px !important',
        height: '35.38px',
      },
    },
    picker: {
      '& [role="cell"]': {},
      '& .MuiCalendarPicker-root > div': {
        marginTop: '0',
      },
    },
    dot: {
      top: '114%',
      right: '50%',
      height: '5px',
      minWidth: '5px',
      '@media (min-width: 1441px)': {
        top: '101%',
        height: '4px',
        minWidth: '4px',
      },
    },
    calendarBottomLabels: {
      padding: '12px 20px 0',
    },
    calendarBottomLabelItem: {
      display: 'flex',
      alignItems: 'center',
      '&:last-of-type': {
        justifyContent: 'flex-end',
      },
    },
    labelDot: {
      height: '5px',
      minWidth: '5px',
    },
    calendarBottomLabelText: {
      fontSize: 11,
      lineHeight: '12px',
      fontWeight: 700,
      paddingLeft: '6px',
    },
    '@media (min-width: 425px)': {
      root: {
        '& .MuiCalendarPicker-root': {
          width: 356,
        },
        '& .css-165tewp': {
          minWidth: 356,
        },
      },
    },
    '@media (min-width: 744px)': {
      root: {
        '& .MuiCalendarPicker-root': {
          width: 356,
        },
        '& .css-165tewp': {
          minWidth: 356,
        },
      },
    },
    '@media (min-width: 1441px)': {
      root: {
        '& .MuiCalendarPicker-root': {
          width: 246,
        },
        '& .css-165tewp': {
          minWidth: 246,
        },
        '& .PrivatePickersSlideTransition-root': {
          minHeight: 183,
        },
      },
    },
    '@media (min-width: 1366px)': {
      root: {},
      sectionBorderRight: {
        minHeight: '100vh',
        margin: 'initial',
        borderRight: '1px solid #DEE2E7',
      },
    },
  })
)

export { useStyles }
