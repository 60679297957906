import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      paddingBottom: 0,
    },
    input: {
      '& .MuiFormHelperText-root': {
        color: theme.palette.grey['500'],
      },
    },
    footer: {
      padding: theme.spacing(2.5),
    },
    margin: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    label: {
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(1),
    },
    row: {
      width: `calc(50% - ${theme.spacing(1)})`,
    },
    closeButton: {
      fill: theme.palette.grey[500],
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  })
)

export { useStyles }
