import { List, ListItem, Grid, Dialog, DialogActions, DialogContent } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { KrowTransition, DialogTitle } from 'shared/components'

function NudgeDialog({ open, onClose, onConfirm, isNudging, candidate }) {
  return (
    <Dialog
      open={open}
      TransitionComponent={KrowTransition}
      keepMounted={false}
      onClose={() => onClose()}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle onClose={() => onClose()}>Nudge Candidate</DialogTitle>

      <Grid>
        <DialogContent dividers>
          <Grid container direction="column" textAlign="center" alignContent="center">
            <Grid item>
              Nudging encourages unscheduled candidates to schedule an interview. Once they have
              been nudged, you will not be able to nudge them again for 24 hours. Do you want to
              nudge {candidate}?
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <List style={{ listStyle: 'none', display: 'flex' }}>
            <ListItem onClick={() => onConfirm()}>
              <LoadingButton loading={isNudging} variant="contained">
                Nudge
              </LoadingButton>
            </ListItem>
          </List>
        </DialogActions>
      </Grid>
    </Dialog>
  )
}
export default NudgeDialog
