import FormControl from '@mui/material/FormControl'
import HelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import clsx from 'clsx'
import { FC } from 'react'
import './krow-wysiwyg-editor-wrapper.css'

interface KrowWysiwygWrapperProps {
  error?: boolean
  label: string
  helperText?: string
}

const KrowWysiwygFormControlWrapper: FC<KrowWysiwygWrapperProps> = ({
  label,
  error,
  helperText,
  children,
}) => {
  const className = clsx({
    'outlined-control': true,
    error: error,
  })

  return (
    <FormControl defaultValue="" variant="outlined" className={className} error={error}>
      <FormLabel
        sx={{
          fontSize: `12px`,
          marginLeft: '14px',
          padding: '1px',
          zIndex: 100,
          background: 'white',
          width: '70px',
        }}
      >
        {label}
      </FormLabel>
      {children}
      {helperText && <HelperText>{helperText}</HelperText>}
    </FormControl>
  )
}

export default KrowWysiwygFormControlWrapper
