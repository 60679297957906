import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: 'black',
      height: '80px',
    },
  })
)

export { useStyles }
