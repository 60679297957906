import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store'

const responseIds = (state: RootState) => state.responses.result
const responsesById = (state: RootState) => state.responses.byId
const mappedResponses = (
  ids: ReturnType<typeof responseIds>,
  byId: ReturnType<typeof responsesById>
) => {
  return ids.map((id) => byId[id])
}

export const responsesSelector = createSelector(responseIds, responsesById, mappedResponses)
