import React from 'react'
import { Route } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query'
import { Scope } from 'store/store.types'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MatToolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactComponent as Logo } from 'assets/images/krow-dark.svg'
import useStyles from './Toolbar.styles'
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg'
import { useGetMeQuery, useGetMyProfileQuery } from 'store/me/me.api'
import { useGetOneOrganizationQuery } from 'store/organizations/organizations.api'
import { useRevokeMutation } from 'store/authentication/authentication.api'
import { BuilderToolbar } from 'routes/Builder/components/BuilderToolbar'
import { useOrgSettings } from 'shared/hooks/useOrgSettings'
import { get } from 'lodash'

interface ToolbarProps {
  open: boolean
  setOpen: (open: boolean) => void
  openUserProfileEditDialog: () => void
}

function Toolbar(props: ToolbarProps) {
  const classes = useStyles()
  const [triggerRevoke] = useRevokeMutation()
  const { open, setOpen, openUserProfileEditDialog } = props
  const { data: profile } = useGetMyProfileQuery()
  const { data: me } = useGetMeQuery()
  const { organizationId } = useParams<Scope>()
  const orgSettings = useOrgSettings()
  const { data: organization } = useGetOneOrganizationQuery(
    organizationId ? organizationId : skipToken
  )

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const ssoEnabled = get(JSON.parse(localStorage.getItem('access')), 'attributes.ssoEnabled')

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar id="app-header" className={classes.root} color="transparent">
      <MatToolbar className={classes.toolbar}>
        <Box className={classes.toolbarLeftSide}>
          <IconButton
            onClick={() => setOpen(!open)}
            size="large"
            sx={{ display: { lg: 'none', xs: 'flex' } }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Link to="/">
              <Logo />
            </Link>
          </Box>
        </Box>
        <Box>
          <Route exact path="/organizations/:organizationId/questionnaires/:questionnaireId">
            <BuilderToolbar />
          </Route>
          <Box className={classes.admin}>
            {organization?.data?.logo && (
              <Box className={classes.border}>
                <img
                  className={classes.clientLogo}
                  src={organization?.data?.logo}
                  alt="logo"
                  height="100%"
                />
              </Box>
            )}

            <Box
              position="relative"
              className={classes.user}
              alignItems="flex-end"
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              <Button
                onClick={handleClick}
                aria-controls="user-menu"
                aria-haspopup="true"
                color="accent"
                endIcon={<ExpandMoreIcon />}
              >
                {profile?.data?.first_name ? (
                  <Typography className={classes.toolbarMenuFullName} variant="body1">
                    {profile?.data?.first_name} {profile?.data?.last_name}
                  </Typography>
                ) : (
                  me?.data?.qualifier
                )}
              </Button>
              <Menu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {!((orgSettings?.ssoEnabled && organizationId) || ssoEnabled) && (
                  <MenuItem
                    onClick={() => {
                      openUserProfileEditDialog()
                      handleClose()
                    }}
                  >
                    Edit Profile
                  </MenuItem>
                )}
                <MenuItem
                  onClick={async () => {
                    await triggerRevoke()
                    window.localStorage.clear()
                    window.location.reload()
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </MatToolbar>
    </AppBar>
  )
}

export default Toolbar
