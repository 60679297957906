import React, { useMemo } from 'react'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import {
  DialogTitle,
  FormClassificationAutocomplete,
  FormMarketAutocomplete,
  KrowTransition,
} from '..'
import { includes, toLower, toString } from 'lodash'
import { LoadingButton } from '@mui/lab'
import { KrowWysiwygEditor } from '../KrowWysiwygEditor'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import KrowWysiwygFormControlWrapper from '../KrowWysiwygEditor/KrowWysiwygFormControlWrapper'

export function OpeningsForm(props) {
  const {
    message,
    control,
    classification,
    open,
    onClose,
    onSubmit,
    isSubmitting,
    Header,
    blackList = [],
  } = props

  const payment = useMemo(
    () => (includes(['hourly'], toLower(classification?.name)) ? 'Per Hour' : 'Per Year'),
    [classification]
  )

  const handleInputChange = (event, onChange) => {
    const value = event.target.value
    const result = toString(Math.max(0, Number(value)))
    onChange(value === '' ? value : result)
  }

  const hide = (field) => !includes(blackList, field)
  return (
    <Dialog
      open={open}
      TransitionComponent={KrowTransition}
      onClose={onClose}
      aria-labelledby="Openings-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle onClose={onClose}>
        <Header />
      </DialogTitle>

      <DialogContent dividers>
        <Stack
          onSubmit={onSubmit}
          id="openings_form"
          component="form"
          gap={3}
          sx={{ paddingTop: 3 }}
        >
          {hide('name') && (
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Name"
                  type="text"
                  error={!!fieldState.error}
                  helperText={fieldState?.error?.message}
                />
              )}
            />
          )}
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <KrowWysiwygFormControlWrapper
                label="Description"
                error={!!fieldState.error}
                helperText={fieldState?.error?.message}
              >
                <KrowWysiwygEditor {...field} />
              </KrowWysiwygFormControlWrapper>
            )}
          />

          <Controller
            name="external_id"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                name="external_id"
                fullWidth
                label="External ID"
                type="text"
                error={!!fieldState.error}
                helperText={fieldState?.error?.message}
              />
            )}
          />

          <Stack direction="row" gap={2}>
            {hide('market') && (
              <Controller
                name="market"
                control={control}
                render={(props) => {
                  return <FormMarketAutocomplete {...props} />
                }}
              />
            )}

            <Controller
              name="classification"
              control={control}
              render={(props) => {
                return <FormClassificationAutocomplete {...props} />
              }}
            />
          </Stack>

          <Stack direction="row" gap={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Controller
              name="minimum"
              control={control}
              render={({ field, fieldState }) => {
                const { onChange, ...rest } = field
                return (
                  <TextField
                    {...rest}
                    name="minimum"
                    fullWidth
                    label="Minimum Rate"
                    type="number"
                    onChange={(e) => handleInputChange(e, onChange)}
                    error={!!fieldState.error}
                    helperText={fieldState?.error?.message}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                )
              }}
            />

            <Controller
              name="maximum"
              control={control}
              render={({ field, fieldState }) => {
                const { onChange, ...rest } = field
                return (
                  <TextField
                    {...rest}
                    type="number"
                    name="maximum"
                    fullWidth
                    onChange={(e) => handleInputChange(e, onChange)}
                    label="Maximum Rate"
                    error={!!fieldState.error}
                    helperText={fieldState?.error?.message}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                )
              }}
            />

            <Typography whiteSpace="nowrap">{payment}</Typography>
          </Stack>
          <Alert severity="info">{message}</Alert>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button autoFocus color="primary" variant="outlined" onClick={onClose} size="small">
          Cancel
        </Button>
        <LoadingButton
          loading={isSubmitting}
          type="submit"
          size="small"
          form="openings_form"
          variant="contained"
          color="primary"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
