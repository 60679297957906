import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.primary,
  },
  formControl: {
    boxShadow: 'none',
    minWidth: 120,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  startIcon: {
    '& .MuiSelect-root': {
      paddingRight: 12,
      paddingLeft: 36,
    },
    '& .MuiSelect-icon': {
      fill: theme.palette.text.secondary,
      left: '12px',
      top: 'unset',
    },
  },
}))

export { useStyles }
