import { api } from '../store.api'
import { providesList } from 'store/store.utils'
import deserialize from 'util/deserialize'

export const positionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOnePosition: build.query({
      query(id) {
        return {
          method: 'GET',
          url: `/positions/${id}`,
        }
      },
      transformResponse: (response) => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result, _, id) => [{ type: 'Position', id }],
    }),
    allPositions: build.query({
      query(scope) {
        const { organizationId, ...params } = scope
        return {
          method: 'GET',
          url: `organizations/${organizationId}/positions`,
          params: {
            ...params,
            included: ['market', 'classification', 'organization'],
          },
        }
      },
      transformResponse: (response) => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result) => (result ? providesList(result.data, 'Position') : []),
    }),
    createPosition: build.mutation({
      query(data) {
        const { organizationId, batchToken, ...attributes } = data
        const headers = batchToken ? { 'X-Batch-Token': batchToken } : undefined
        return {
          method: 'POST',
          url: `organizations/${organizationId}/positions`,
          body: {
            data: {
              attributes,
            },
          },
          headers: headers,
        }
      },
      invalidatesTags: [{ type: 'Position', id: 'LIST' }],
    }),
    updatePosition: build.mutation({
      query(data) {
        const { id, ...attributes } = data
        return {
          method: 'PATCH',
          url: `positions/${id}`,
          body: {
            data: {
              attributes,
            },
          },
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Position', id }],
    }),
    deletePosition: build.mutation({
      query(id) {
        return {
          method: 'DELETE',
          url: `positions/${id}`,
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'Position', id }],
    }),
  }),

  overrideExisting: false,
})

export const {
  useGetOnePositionQuery,
  useAllPositionsQuery,
  useCreatePositionMutation,
  useUpdatePositionMutation,
  useDeletePositionMutation,
} = positionsApi
