import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import dayjs, { Dayjs } from 'dayjs'
import { KrowStaticDatePicker } from 'shared/components'

interface KrowCalendarDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  date: dayjs.Dayjs | null
  handleSetDate: (date: Dayjs | null) => void
  availableDays: string[]
}

function KrowCalendarDialog(props: KrowCalendarDialogProps) {
  const { date, handleSetDate, availableDays, open, setOpen } = props

  return (
    <Dialog open={open} keepMounted={false} onBackdropClick={() => setOpen(false)}>
      <DialogContent>
        <KrowStaticDatePicker
          date={date}
          handleSetDate={handleSetDate}
          availableDays={availableDays}
        />
      </DialogContent>
    </Dialog>
  )
}

export default KrowCalendarDialog
