import { api } from '../store.api'
import { KrowIdentity, KrowDocument, KrowResponse, KrowPartialResource } from 'store/industry'
import deserialize from 'util/deserialize'
import { OrganizationResponse } from 'store/organizations'
import { OrganizationResult } from 'store/organizations/organizations.api'

export interface ProfileAttributes {
  created_at: string
  updated_at: string
  full_name: string
  first_name: string
  last_name: string
  phone_number: string
}

export interface IncludedProfile extends Profile {}
export type Profile = KrowIdentity<'profile'> & ProfileAttributes
export type ProfilePartialResource = KrowPartialResource<ProfileAttributes>
export type ProfileDocument = KrowDocument<'manager', ProfileAttributes>
export type ProfileResponse = KrowResponse<ProfileDocument>
export type ProfileResult = KrowResponse<IncludedProfile>

export const meApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMyProfile: build.query<ProfileResult, void>({
      query() {
        return {
          method: 'GET',
          url: `me/profile`,
        }
      },
      providesTags: ['Me'],
      transformResponse: (response: ProfileResponse): ProfileResult => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
    }),
    getMe: build.query<any, void>({
      query() {
        return {
          method: 'GET',
          url: `me`,
          params: {
            included: ['profile'],
          },
        }
      },
      transformResponse: (response: OrganizationResponse): OrganizationResult => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (response) => [{ type: 'Organization', id: response?.data.id }],
    }),
    getMyOrganizations: build.query<Profile, void>({
      query() {
        return {
          method: 'GET',
          url: `me/organizations`,
        }
      },
      providesTags: ['Me'],
    }),
    updateMyProfile: build.mutation<ProfileResponse, Partial<ProfileAttributes>>({
      query(attributes) {
        return {
          method: 'PATCH',
          url: `me/profile`,
          body: {
            data: { attributes },
          },
        }
      },
      invalidatesTags: ['Me'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetMyProfileQuery,
  useGetMyOrganizationsQuery,
  useGetMeQuery,
  useUpdateMyProfileMutation,
} = meApi
