import NotFound from 'routes/NotFound'
import { useParams } from 'react-router-dom'
import { Scope } from 'store/store.types'
import { useGetOneOrganizationQuery } from 'store/organizations/organizations.api'
import { isHandledQueryError } from 'store/store.utils'
import { OrgSettingsProvider } from 'shared/hooks/useOrgSettings'

interface ValidateOrganizationParamProps {
  children: JSX.Element
}

function ValidateOrganizationParam(props: ValidateOrganizationParamProps) {
  const { organizationId } = useParams<Scope>()
  const org = useGetOneOrganizationQuery(organizationId)
  const isOrganizationNotFound = isHandledQueryError(org.error) && org.error.status === 404

  return isOrganizationNotFound ? (
    <NotFound />
  ) : (
    <OrgSettingsProvider>{props.children}</OrgSettingsProvider>
  )
}

export default ValidateOrganizationParam
