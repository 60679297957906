import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  Typography,
  Skeleton,
  Divider,
  useTheme,
  Box,
  Checkbox,
} from '@mui/material'

import ReportIcon from '@mui/icons-material/Report'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'

import {
  useAllDirectionsQuery,
  useRemoveDirectionMutation,
  useUpdateDirectionMutation,
} from 'store/directions/direction.api'

import { schedule as manipulator, summarize } from 'routes/OrgSettings/Itinerary/Schedule'
import { useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGetMeQuery } from 'store/me/me.api'

function Direction({ selected, direction, select }) {
  const theme = useTheme()
  const ical = direction.ical
  const schedule = manipulator.parse({ ical, directed: direction })

  return (
    <Grid container gap={theme.spacing(2)} pb={theme.spacing(2)}>
      <Grid item>
        <Checkbox checked={selected} onChange={select} />
      </Grid>
      <Grid item>
        <Typography variant="body2">{summarize(schedule)}</Typography>
        <Typography variant="caption">
          {schedule.starting.format('MM/DD/YYYY hh:mm a')} -{' '}
          {schedule.until === undefined
            ? schedule.ending.format('h:mm a')
            : schedule.until.format('MM/DD/YYYY hh:mm a')}
        </Typography>
      </Grid>
    </Grid>
  )
}

function DirectionDialog({ type, opened, close, directable }) {
  const theme = useTheme()

  const [directed, direct] = useState(null)
  const [dirty, changed] = useState(false)
  const [strategy, apply] = useState('sliced')
  const [page, progress] = useState(1)

  const [update] = useUpdateDirectionMutation()
  const [remove] = useRemoveDirectionMutation()

  const { organizationId } = useParams()

  const { data: directions, isLoading: fetching } = useAllDirectionsQuery(
    { type, id: directable?.id, page: { number: page, size: 5 } },
    { skip: 'undefined' === typeof directable || null === directable }
  )

  const undirecting = dirty && directed === null && 'undefined' !== typeof directable?.direction_id

  const { data: me } = useGetMeQuery()

  const access = me?.data.type
  const adminAccess = ['active_administrators', 'account_managers'].some((type) => type === access)

  useEffect(
    () =>
      direct(
        directions?.data.find((direction) => direction.id === directable?.direction_id) || null
      ),
    [opened, directable, directions]
  )

  useEffect(() => apply(directions?.meta?.pagination ? 'paginated' : 'sliced'), [directions])

  const pages = useMemo(
    () =>
      strategy === 'sliced'
        ? Math.ceil(directions?.data.length / 5)
        : directions?.meta?.pagination?.total,
    [strategy, directions]
  )

  const reset = () => direct(null) || changed(false) || close()

  const save = async (e) => {
    e.preventDefault()

    if (undirecting) {
      ;(await remove({
        type,
        id: directable.id,
        direction: { id: directable.direction_id },
      }).unwrap()) || reset()
    } else {
      ;(await update({ type, id: directable.id, direction: directed }).unwrap()) || reset()
    }
  }

  return (
    <Dialog fullWidth open={opened}>
      <DialogContent pb="0">
        <Grid container direction="column" mb={theme.spacing(2)}>
          <Typography variant="h6">Direction</Typography>
          <Typography variant="body2">
            Direct all {directable?.name} Candidates to a specific Schedule
          </Typography>
        </Grid>

        <Divider width="100%" />

        <Grid container direction="column" mt={theme.spacing(2)} mb={theme.spacing(2)}>
          <Grid container flex="1" sx={{ display: fetching ? 'flex' : 'none' }}>
            <Skeleton animation="wave" />
          </Grid>
          <Grid
            container
            flex="1"
            gap={theme.spacing(2)}
            sx={{ display: fetching ? 'none' : directions?.data.length ? 'flex' : 'none' }}
          >
            <Grid container>
              {(
                (strategy === 'sliced'
                  ? directions?.data.slice((page - 1) * 5, page * 5)
                  : directions?.data) || []
              ).map((direction) => (
                <Direction
                  key={direction.id}
                  direction={direction.attributes}
                  selected={direction.id === directed?.id}
                  select={() =>
                    changed(true) || direction.id === directed?.id
                      ? direct(null)
                      : direct(direction)
                  }
                />
              ))}
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              gap={theme.spacing(2)}
              mt={theme.spacing(2)}
            >
              <Grid item>
                <IconButton
                  size="small"
                  disabled={page === 1}
                  onClick={() => progress((current) => current - 1)}
                >
                  <ArrowCircleLeftIcon
                    fontSize="small"
                    color={page === 1 ? 'inherit' : 'secondary'}
                  />
                </IconButton>
              </Grid>

              <Grid item>
                <Typography variant="body2">
                  {page} of {pages}
                </Typography>
              </Grid>

              <Grid item>
                <IconButton
                  size="small"
                  disabled={page === pages}
                  onClick={() => progress((current) => current + 1)}
                >
                  <ArrowCircleRightIcon
                    fontSize="small"
                    color={page === pages ? 'inherit' : 'secondary'}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            flex="1"
            justifyContent="center"
            gap={theme.spacing(2)}
            sx={{ display: fetching ? 'none' : directions?.data.length ? 'none' : 'flex' }}
          >
            <Grid item>
              <Typography textAlign="center">Not so Fast</Typography>
              <Typography textAlign="center" variant="body2">
                There are currently no Schedules which support direction.
                {adminAccess ? (
                  <Box display="inline">
                    {' '}
                    Click below to continue to the Organization's Settings to create a directed
                    Schedule.
                  </Box>
                ) : (
                  <Box display="inline">
                    {' '}
                    Contact your Account Manager if you need a directed schedule created to
                    associate with this position.
                  </Box>
                )}
              </Typography>
            </Grid>
            {adminAccess && (
              <Grid item>
                <Button component={Link} to={`/organizations/${organizationId}/org_settings`}>
                  Settings
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Divider width="100%" />
      </DialogContent>
      <DialogActions>
        <Grid container direction="row">
          <Grid
            container
            flex="1"
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            wrap="nowrap"
            sx={{ display: undirecting ? 'flex' : 'none' }}
            gap={theme.spacing(2)}
          >
            <Grid item>
              <ReportIcon color="error" />
            </Grid>
            <Grid item>
              <Typography variant="body2">
                Saving will remove the direction to the current Schedule.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            flex="1"
            justifyContent="flex-end"
            alignItems="center"
            gap={theme.spacing(2)}
          >
            <Grid item>
              <Button onClick={reset}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button
                onClick={save}
                variant="contained"
                disabled={
                  !directions?.data.length || directed?.id === directable?.direction_id || !dirty
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
export default DirectionDialog
