import { api } from '../store.api'
import { providesList } from 'store/store.utils'
import { OrganizationScope, ListQueryParams } from 'store/store.types'
import {
  KrowResponse,
  KrowIdentity,
  KrowDocument,
  KrowResource,
  KrowCollection,
  KrowPartialResource,
} from 'store/industry'
import deserialize from 'util/deserialize'

export type TRegionAttributes = {
  name: string
  external_id: string
  created_at: string
  updated_at: string
  rolling_daily_apply_count: number | null
  rolling_monthly_apply_count: number | null
  rolling_daily_hire_count: number | null
  rolling_monthly_hire_count: number | null
  average_days_to_decision: number | null
}

export type TRegionDocument = KrowDocument<'region', TRegionAttributes>
export type TRegionResource = KrowResource<'region', TRegionAttributes>
export type TRegionCollection = KrowCollection<'region', TRegionAttributes>
export type TRegionPartialResource = KrowPartialResource<TRegionAttributes>

export interface IncludedRegion extends Region {}
export type Region = KrowIdentity<'opening'> & TRegionAttributes
export type RegionPartialResource = KrowPartialResource<TRegionAttributes>
export type RegionDocument = KrowDocument<'opening', TRegionAttributes>
export type RegionResponse = KrowResponse<RegionDocument>
export type RegionCollectionResponse = KrowResponse<RegionDocument[]>
export type RegionResult = KrowResponse<IncludedRegion>
export type RegionCollectionResult = KrowResponse<IncludedRegion[]>

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    listRegions: build.query<TRegionCollection, ListQueryParams & OrganizationScope>({
      query({ organizationId, search, sort, page = { number: 1, size: 25 } }) {
        return {
          method: 'GET',
          url: `organizations/${organizationId}/regions`,
          params: {
            search,
            sort,
            page,
          },
        }
      },
      transformResponse: (result: TRegionCollection) => {
        return result
      },
      providesTags: (result) => (result?.data ? providesList(result?.data, 'Region') : []),
    }),
    createRegion: build.mutation<
      TRegionResource,
      Partial<TRegionAttributes> & OrganizationScope & { batchToken?: string }
    >({
      query(scope) {
        const { organizationId, batchToken, ...attributes } = scope
        const headers = batchToken ? { 'X-Batch-Token': batchToken } : undefined

        return {
          method: 'POST',
          url: `organizations/${organizationId}/regions`,
          body: {
            data: { type: 'region', attributes },
          },
          headers: headers,
        }
      },
      invalidatesTags: [{ type: 'Region', id: 'LIST' }],
    }),
    /* Existing */
    allRegions: build.query<RegionCollectionResult, ListQueryParams & OrganizationScope>({
      query(scope) {
        const { organizationId, ...params } = scope
        return {
          method: 'GET',
          url: `organizations/${organizationId}/regions`,
          params: {
            ...params,
            included: ['locations', 'organization'],
          },
        }
      },
      transformResponse: (response: RegionCollectionResponse): RegionCollectionResult => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result) => providesList(result?.data, 'Region'),
    }),
    getOneRegion: build.query<RegionResult, string>({
      query(id) {
        return {
          method: 'GET',
          url: `/regions/${id}`,
        }
      },
      transformResponse: (response: RegionResponse): RegionResult => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result, _, id) => [{ type: 'Region', id }],
    }),
    updateRegion: build.mutation<RegionResponse, Partial<TRegionAttributes> & { id: string }>({
      query(data) {
        const { id, ...attributes } = data
        return {
          method: 'PATCH',
          url: `regions/${id}`,
          body: {
            data: {
              attributes,
            },
          },
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Region', id }],
    }),
    deleteRegion: build.mutation<void, string>({
      query(id) {
        return {
          method: 'DELETE',
          url: `regions/${id}`,
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'Region', id }],
    }),
  }),

  overrideExisting: false,
})

export const {
  useListRegionsQuery,
  useCreateRegionMutation,
  useAllRegionsQuery,
  useGetOneRegionQuery,
  useUpdateRegionMutation,
  useDeleteRegionMutation,
} = extendedApi
