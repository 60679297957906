import { createSlice, SliceCaseReducers, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'
import { AlertProps } from '@mui/material/Alert'

export interface Snack {
  id: string
  message: string
  severity: AlertProps['severity']
}

export type SnackState = Snack[]

const snacksSlice = createSlice<SnackState, SliceCaseReducers<SnackState>>({
  name: 'snacks',
  initialState: [],
  reducers: {
    addSnack(state, snack: PayloadAction<Omit<Snack, 'id'>>) {
      state.push({ id: uuid(), ...snack.payload })
      return state
    },
    removeSnack(state, action: PayloadAction<Snack>) {
      return state.filter((snack) => snack.id !== action.payload.id)
    },
  },
})

export const { addSnack, removeSnack } = snacksSlice.actions

export default snacksSlice
