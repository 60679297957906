import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
    },
    blur: {
      filter: 'blur(6px)',
    },
    loading: {
      width: '195px',
      height: '195px',
      marginBottom: theme.spacing(3),
    },
    loadingText: {
      color: theme.palette.primary.main,
    },
  })
)

export default useStyles
