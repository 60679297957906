import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: '#ffff',
      boxShadow: '0 2px 7px 0 rgba(36,36,36,0.2)',
      padding: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1, 2),
      },
      zIndex: theme.zIndex.modal,
    },
    toolbar: {
      justifyContent: 'space-between',
    },
    toolbarLeftSide: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    logo: {
      maxWidth: '130px',
      marginTop: 16,
      marginLeft: 31,
      [theme.breakpoints.up('lg')]: {
        marginTop: 0,
        marginLeft: 0,
      },
    },
    clientLogo: {
      height: '100%',
      maxHeight: 36,
    },
    admin: {
      alignItems: 'center',
      display: 'flex',
    },
    user: {
      paddingLeft: theme.spacing(2),
    },
    border: {
      [theme.breakpoints.up('md')]: {
        borderRight: `1px solid ${theme.palette.grey['400']}`,
        paddingRight: '32px',
      },
    },
    avatar: {
      border: `2px solid ${theme.palette.secondary.main}`,
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(7),
        width: theme.spacing(7),
      },
    },
    toolbarMenuFullName: {
      textTransform: 'capitalize',
    },
  })
)

export default useStyles
