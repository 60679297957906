import Link from '@mui/material/Link'

interface EmailLinkProps {
  component: any
  email: string
  subject: string
  color: string
}

function EmailLink(props: EmailLinkProps) {
  const { component, subject, email, color, ...other } = props

  return (
    <Link
      {...other}
      style={{ color: color }}
      underline="hover"
      component={component}
      href="#"
      onClick={(e: any) => {
        window.location.href = `mailto:${email}?subject=${subject}`
        e.preventDefault()
      }}
    >
      {email}
    </Link>
  )
}

export default EmailLink
